import React, { useEffect } from 'react'
import { useGeoState, useGeoFetch } from '../../components/GeoProvider'
import { useAuthState } from '../../components/AuthProvider'
import ErrorLogs from '../../components/ErrorBoundary/errorlogs'
import { lastweekEndDate } from './dates'
const Zip3Api = ({  setMapData, setStatsData, setActiveLocationData,setMapJsonData }) => {    
  const {
    dataStartDate,
    dataEndDate,
    setDisplayDate
  } = useGeoState()  
  var geo_parent = 'US'
  const { user } = useAuthState()

  const { data: zip3Stats, error: zip3DataError } = useGeoFetch(
    lastweekEndDate,
    lastweekEndDate,
    'fluStats',
    'ZIP3/'
  )

  useEffect(() => {
    if (zip3Stats !== null && zip3Stats !== undefined) {
      if (zip3Stats.response_code === 200) {
        setStatsData(zip3Stats.response_data)
      } else if (zip3Stats.response_code === 404) {
        setStatsData(zip3Stats.response_data)
        ErrorLogs({
          message_type: 'Error',
          error_details: `${zip3Stats.response_subject}, ${zip3Stats.response_message},for ${geo_parent}`,
          user: user,
        })
      } else {
        // setErrorCode(zip3Stats.response_code)
        // setErrorMessage(zip3Stats.response_message)
        // setErrorSubject(zip3Stats.response_subject)
        // seterrorOccured(true)
      }
    }
  }, [zip3Stats])



  const { data: activeZIP3Data, error: activeZIP3DataError } = useGeoFetch(
    dataStartDate,
    dataEndDate,
    'fluLocations',
    'activelocations?geo_type=ZIP3'
  )

  useEffect(() => {
    if (activeZIP3Data !== null && activeZIP3Data !== undefined) {
      setActiveLocationData(activeZIP3Data)
    }
  }, [activeZIP3Data])

  
  const { data: zip3Datanew, error: MapZipError1 } = useGeoFetch(
     dataStartDate,
    dataEndDate,
    'fluMap',
    'ZIP3/?geo_parent=US'
  )

  useEffect(() => {
    if (zip3Datanew !== null && zip3Datanew !== undefined) {
      if (zip3Datanew.response_code === 200) {        
        setMapData(zip3Datanew.response_data)
      } else {
      
      }
    }
  }, [zip3Datanew])

useEffect(() => {
  let url = `${window.location.origin}/three_dig_zips.json`
  fetch(url)
    .then(function (res) {
      return res.json();
    })
    .then(function (data) {
      setMapJsonData(data);
    })
    .catch(function (err) {
      console.log(err, " error file load us_hrr_values ");
    });
}, [1]);

  return (
    <>
    
    </>
  )
}

export default Zip3Api