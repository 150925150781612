import React, { useState, useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { useGeoState, useGeoFetch } from '../../../components/GeoProvider'
import ErrorMessage from '../../../components/Layout/ErrorMessage'
import Spinner from '../../../components/Loader/Spinner'
import EmptyMap from '../../../components/Map/emptyMap'
import Map from '../../../components/Map'
import toolTipFormatter from '../toolTipFormatter'
import VulMapLegend from '../vulnerabilityMapLegendHorizontal'
import SideTable from './SideTable'
import styles from './MapPage.module.scss'
import dayjs from 'dayjs'
import ErrorLogs from '../../../components/ErrorBoundary/errorlogs'
import { useAuthState } from '../../../components/AuthProvider'
import DisplayDateProvider from '../../../components/DisplayDateProvider/DisplayDateProvider'
import HorizontalColumnBarChart from '../../../components/HorizontalColumnBarChart'
import closeIcon from '../../../assets/images/icon/close_24dp.png'
const DashboardMap = ({ geo_type, geo_parent, zoomLocation }) => {
  const { user } = useAuthState()
  const chartRef = useRef(null)
  const location = useLocation()
  const hash = location.hash
  const [getMapStates, setMapStates] = useState()
  const [metros, setMetros] = useState()
  const [getMedicalFactorData, setMedicalFactorData] = useState()
  const [activeCbsa, setActiveCbsa] = useState()
  const [getStats, setStats] = useState()
  const [errorCode, setErrorCode] = useState()
  const [errorMessage, setErrorMessage] = useState()
  const [errorSubject, setErrorSubject] = useState()
  const [errorOccured, seterrorOccured] = useState(false)
  const [getCBSAJsonData, setCBSAJsonData] = useState()
  const [getStateJson, setStateJson] = useState()
  const {
    dataStartDate,
    dataEndDate,
    mapOptions,
    regionSelected,
    setRegionSelected,
    isChecked,
    locationName,
    displayDate,
    setDisplayDate,
    setIsChecked,
  } = useGeoState()

  if (hash) {
    if (hash.split('-').length > 1) {
      setRegionSelected(hash.split('-')[0].replace('#', ''))
    }
  }

  const lastweekEndDate = dayjs().day(-1).format('YYYY-MM-DD')

  const { data: medData, error: medDataError } = useGeoFetch(
    lastweekEndDate,
    lastweekEndDate,
    'vulnerability',
    'getmedicalfactor'
  )

  useEffect(() => {
    if (medData !== null && medData !== undefined) {
      if (medData.response_code === 200) {
        setMedicalFactorData(medData.response_data)
      } else {
        setErrorCode(medData.response_code)
        setErrorMessage(medData.response_message)
        setErrorSubject(medData.response_subject)
        seterrorOccured(true)
      }
    }
  }, [medData])

  const { data: statesData, error: MapError } = useGeoFetch(
    dataStartDate,
    dataEndDate,
    'vulnerability',
    'getdashmap/STATE?geo_parent=US'
  )

  useEffect(() => {
    if (statesData !== null && statesData !== undefined) {
      if (statesData.response_code === 200) {
        var datacount = Object.keys(statesData.response_data).length - 1
        setDisplayDate(Object.keys(statesData.response_data)[datacount])
        setMapStates(statesData.response_data)
      } else {
        setErrorCode(statesData.response_code)
        setErrorMessage(statesData.response_message)
        setErrorSubject(statesData.response_subject)
        seterrorOccured(true)
      }
    }
  }, [statesData])

  const { data: msaData, error: MapmsaError } = useGeoFetch(
    dataStartDate,
    dataEndDate,
    'vulnerability',
    'getdashmap/MSA?geo_parent=' + geo_parent
  )

  useEffect(() => {
    if (msaData !== null && msaData !== undefined) {
      if (msaData.response_code === 200) {
        setMetros(msaData.response_data)
      } else if (msaData.response_code === 404) {
        setMetros(msaData.response_data)
        ErrorLogs({
          message_type: 'Error',
          error_details: `${msaData.response_subject}, ${msaData.response_message},for ${geo_parent}`,
          user: user,
        })
      } else {
        setErrorCode(msaData.response_code)
        setErrorMessage(msaData.response_message)
        setErrorSubject(msaData.response_subject)
        seterrorOccured(true)
      }
    }
  }, [msaData])

  const { data: stats, error: stateDataError } = useGeoFetch(
    lastweekEndDate,
    lastweekEndDate,
    'vulnerability',
    'getsidetable/MSA?geo_parent=' + geo_parent
  )

  useEffect(() => {
    if (stats !== null && stats !== undefined) {
      if (stats.response_code === 200) {
        setStats(stats.response_data)
      } else {
        setErrorCode(stats.response_code)
        setErrorMessage(stats.response_message)
        setErrorSubject(stats.response_subject)
        seterrorOccured(true)
      }
    }
  }, [stats])

  // Active Metro Areas
  const { data: activeCbsasData, error: activeCbsasDataError } = useGeoFetch(
    dataStartDate,
    dataEndDate,
    'vulnerability',
    'getactivelocations?geo_type=MSA&geo_parent_code=' + geo_parent
  )

  useEffect(() => {
    if (activeCbsasData !== null && activeCbsasData !== undefined) {
      if (
        activeCbsasData.response_code === 200 ||
        activeCbsasData.response_code === 201
      ) {
        setActiveCbsa(activeCbsasData.response_data)
      } else {
        setErrorCode(stats.response_code)
        setErrorMessage(stats.response_message)
        setErrorSubject(stats.response_subject)
        seterrorOccured(true)
      }
    }
  }, [activeCbsasData])

  useEffect(() => {
    let url = `${window.location.origin}/usStatesAll.json`
    fetch(url)
      .then(function (res) {
        return res.json()
      })
      .then(function (data) {
        setStateJson(data)
      })
      .catch(function (err) {
        console.log(err, ' error file usStatesAll.json')
      })
  }, [1])
  useEffect(() => {
    let url = `${window.location.origin}/cb_2018_us_cbsa_20m_wkid102004.json`
    fetch(url)
      .then(function (res) {
        return res.json()
      })
      .then(function (data) {
        setCBSAJsonData(data)
      })
      .catch(function (err) {
        console.log(err, ' error file us_hrr_values ')
      })
  }, [1])
  if (
    stateDataError ||
    MapError ||
    errorOccured ||
    activeCbsasDataError ||
    MapmsaError
  ) {
    return (
      <ErrorMessage
        errorCode={errorCode}
        errorMessage={errorMessage}
        errorSubject={errorSubject}
        errorDetails={errorCode === 404 ? `for ${geo_parent}` : errorMessage}
      />
    )
  }

  return (
    <div className={`${styles.base}`}>
      <div className={`${styles.cbsasPanel}`}>
        <>
          {regionSelected === 'state' && (
            <>
              {getStats !== undefined ? (
                <SideTable
                  stateData={getStats}
                  chartRef={chartRef}
                  geo_parent={geo_parent}
                  geo_id={zoomLocation}
                />
              ) : (
                <div style={{ border: '1.5px solid #CBCCCD', height: '84vh' }}>
                  <Spinner />
                </div>
              )}
            </>
          )}
        </>
      </div>
      <div className={`${styles.map}`}>
        {regionSelected === 'state' && (
          <>
            {metros !== undefined &&
            getMapStates !== undefined &&
            activeCbsa !== undefined &&
            getCBSAJsonData ? (
              <>
                <div className={`${styles.providerHeader}`}>
                  <DisplayDateProvider
                    textShow={'Respiratory Virus Activity '}
                    showWeek={false}
                    lastweekEndDate={displayDate}
                    dashType={'VULNERABILITY'}
                  />
                </div>
                {isChecked && (
                  <div className={styles.multifactorGraph}>
                    <div className={styles.multifactorGraphHeader}>
                      <div
                        className={styles.captionFormat}
                        style={{ width: '98%' }}
                      >
                        <span>Factors Impacting Outcome</span>
                      </div>
                      <div>
                        <img
                          className={styles.closePopup}
                          src={closeIcon}
                          alt="Close"
                          onClick={() => setIsChecked(!isChecked)}
                        ></img>
                      </div>
                    </div>
                    <div>
                      {getMedicalFactorData !== undefined ? (
                        <>
                          <HorizontalColumnBarChart
                            data={getMedicalFactorData}
                            chartName="columnBarChart"
                            locationName={locationName}
                          />
                        </>
                      ) : null}
                    </div>
                  </div>
                )}

                <div className={styles.legendDiv}>
                  <VulMapLegend />
                </div>
                <Map
                  key={location?.pathname.split('/').at(-1)}
                  toolTipFormatter={toolTipFormatter}
                  activeCbsasData={activeCbsa}
                  statesData={getMapStates}
                  cbsaData={metros}
                  mapOptions={mapOptions}
                  dynamicMapTitle={true}
                  mapType="RSVMAPMSA"
                  enableButtons={true}
                  zoomLocation={zoomLocation}
                  chartRef={chartRef}
                  mapDataType={geo_type === 'STATE' ? 0 : 1}
                  JsonData={getCBSAJsonData}
                  stateJson={getStateJson}
                  dashboardType="Vulnerability"
                />
              </>
            ) : (
              <EmptyMap />
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default DashboardMap
