import React, { useState, useEffect } from 'react'
import AppName from './AppName'
import Container from '../Container'
import Logo from '../Logo'
import { useHistory, useLocation, Link } from 'react-router-dom'
import { ThemeContext } from '../Theme'
import styles from './Header.module.scss'
import { useAuthState } from '../../components/AuthProvider'
import ProfileHeader from '../ProfileHeader'
import authProvider from '../AuthProvider/ActivateDirectoryAuthProvider'
import HeaderLinks from '../HeaderLinks'
import { useAuth } from '../AuthProvider'
import Menu from '../Menu'
import ListIncidentRequest from '../../pages/ServiceNow/ListIncidentRequest'
import Timeout from '../Timeout/Timeout'
import { tickToTimeStamp } from '../../utils/tickToTimeStamp'

const Header = ({ appName, children = null, logoUrl }) => {
  const theme = React.useContext(ThemeContext)
  const history = useHistory()
  const [{ isAuthenticated, user, authMode }] = useAuth()

  const [showInfoDialog, setShowInfoDialog] = useState(false)
  function formOpen() {
    setShowInfoDialog((current) => !current)
  }
  const sendDataToParent = () => {
    setShowInfoDialog((current) => !current)
  }

  return (
    <>
      {' '}
      <header className={`${styles.base} ${styles[theme]}`}>
        <Container className={styles.container}>
          <div className={styles.sideLogo}>
            <a className={`${styles.logo} ${styles.pointer}`} href={logoUrl}>
              <Logo />
            </a>
            {appName && <AppName>{appName}</AppName>}
          </div>

          {children && <div className={styles.items}>{children}</div>}
          {isAuthenticated && (
            <div className={styles.rightMenu}>
              <div
                className={styles.aboutMenu}
                onClick={() => {
                  history.push('/about')
                }}
              >
                <p>About</p>
              </div>
              <div className={styles.nameInitials}>
                <p>{user.sortName}</p>
              </div>
              {/* <div className={styles.userName}>
              <p>
              {user.firstName} {user.lastName}
              </p>
              <p>{user.role}</p>
            </div> */}
              <div className={styles.signDiv}>
                <ProfileHeader>
                  <HeaderLinks>
                    {isAuthenticated ? (
                      <>
                        {/* <svg
                width="16"
                height="20"
                viewBox="0 0 18 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                >
                <path
                d="M17.6001 17.9004L15.8001 16.1004V10.0004C15.8001 6.75039 13.5501 4.05039 10.5501 3.35039C10.8501 3.05039 11.0001 2.60039 11.0001 2.15039C11.0001 1.05039 10.1001 0.150391 9.00007 0.150391C7.90007 0.150391 7.00007 1.05039 7.00007 2.15039C7.00007 2.60039 7.15007 3.05039 7.45007 3.40039C4.45007 4.10039 2.20007 6.80039 2.20007 10.0504V16.1004L0.400073 17.9004C0.250073 18.0504 0.200073 18.2504 0.300073 18.4504C0.400073 18.6504 0.550073 18.7504 0.750073 18.7504H7.40007C7.15007 19.1004 7.00007 19.5004 7.00007 19.9504C7.00007 21.0504 7.90007 21.9504 9.00007 21.9504C10.1001 21.9504 11.0001 21.0504 11.0001 19.9504C11.0001 19.5004 10.8501 19.1004 10.6001 18.7504H17.2501C17.4501 18.7504 17.6501 18.6504 17.7001 18.4504C17.8001 18.2504 17.7501 18.0004 17.6001 17.9004ZM9.00007 20.9504C8.45007 20.9504 8.00007 20.5004 8.00007 19.9504C8.00007 19.4004 8.45007 18.9504 9.00007 18.9504C9.55007 18.9504 10.0001 19.4004 10.0001 19.9504C10.0001 20.5004 9.55007 20.9504 9.00007 20.9504ZM9.00007 1.15039C9.55007 1.15039 10.0001 1.60039 10.0001 2.15039C10.0001 2.70039 9.55007 3.15039 9.00007 3.15039C8.45007 3.15039 8.00007 2.70039 8.00007 2.15039C8.00007 1.60039 8.40007 1.15039 9.00007 1.15039ZM1.90007 17.7504L3.00007 16.6504C3.10007 16.5504 3.15007 16.4504 3.15007 16.3004V10.0004C3.15007 6.80039 5.75007 4.15039 9.00007 4.15039C12.2001 4.15039 14.8501 6.75039 14.8501 10.0004V16.2504C14.8501 16.4004 14.9001 16.5004 15.0001 16.6004L16.1001 17.7004H1.90007V17.7504Z"
                fill="#424242"
                />
              </svg> */}
                        <HeaderLinks.Item
                          label={user.firstName + ' ' + user.lastName}
                        >
                          <Menu menutype="profile">
                            <Menu.Item
                              // render={() => (
                              //   <Link  style={{ textAlign: 'right' }} to="" onClick={formOpen}>
                              //     Reported Issues
                              //   </Link>
                              // )}
                              label="Reported Issue"
                              onClick={formOpen}
                            />
                            <div
                              style={{
                                border: '1px solid #F4F4F4',
                                marginLeft: '19px',
                                marginRight: '18px',
                              }}
                            ></div>
                            {authProvider && authMode === 'ohid' ? (
                              <Menu.Item
                                render={() => (
                                  <Link
                                    style={{ textAlign: 'right' }}
                                    to="/signout"
                                  >
                                    Sign Out
                                  </Link>
                                )}
                              />
                            ) : (
                              <Menu.Item
                                render={() => (
                                  <Link style={{ textAlign: 'right' }} to="/">
                                    Sign Out
                                  </Link>
                                )}
                              />
                            )}
                          </Menu>
                        </HeaderLinks.Item>
                      </>
                    ) : (
                      <HeaderLinks.Item
                        render={() => <Link to="/">Sign In</Link>}
                      />
                    )}
                  </HeaderLinks>
                </ProfileHeader>
                {/* <div className={styles.signButton}>
                {' '}
                <span
                  onClick={() => {
                    history.push('/signout')
                  }}
                  >
                  Sign Out
                </span>
              </div> */}
                <Timeout
                  endTime={
                    localStorage.getItem('exp')
                      ? tickToTimeStamp(+localStorage.getItem('exp'))
                      : Date.now() + 30 * 60 * 1000
                  }
                  showCountDownDuration={300000}
                  redirectPath={'/signout'}
                />
              </div>
            </div>
          )}
        </Container>
      </header>
      {showInfoDialog && isAuthenticated && (
        <ListIncidentRequest
          key={'data'}
          istrue={showInfoDialog}
          sendDataToParent={sendDataToParent}
        />
      )}
    </>
  )
}

export default Header
