import React, { useState } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { ToastContainer, toast } from 'react-toastify'
import axios from 'axios'
import { IoClose } from 'react-icons/io5'
import { MdDeleteForever } from 'react-icons/md'
import TextBox from '../../components/TextBox'
import { useAuthState } from '../../components/AuthProvider'
import DropDownList from '../../components/DropDownList'
import IssueType from './issuetype'
import Priority from './priority'
import TextArea from '../../components/TextArea'
import ButtonList from '../../components/ButtonList'
import Button from '../../components/Button'
import styles from './IncidentForm.module.scss'
import FileBase64 from 'react-file-base64'
import Label from '../../components/Label'

const IncidentForm = ({ handleClose }) => {
  const phoneNumberRegex =
    /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/
  const MAX_FILE_BYTE_SIZE = 5242880
  const VALID_FILE_FORMATS = [
    'application/json',
    'image/jpg',
    'text/html',
    'application/pdf',
    'application/msword',
    'text/plain',
    'image/png',
  ]
  const API_URL = `${process.env.REACT_APP_BACKEND_BASE_URL}/createincident/`
  const { user } = useAuthState()

  const [resetFile, setResetFile] = useState(false)
  const [getFileTrue, setFileTrue] = useState(true)
  const transformPhoneNumber = (num) => {
    return num
      ? '(' + num.slice(0, 3) + ') ' + num.slice(3, 6) + '-' + num.slice(6)
      : ''
  }

  let name, email, phone
  if (user) {
    let phoneNumber = user?.phone.slice(2)
    let transformedPhone = transformPhoneNumber(phoneNumber)
    name = user?.firstName ?? ''
    email = user?.email ?? ''
    phone = transformedPhone ?? ''
  }

  const intialValues = {
    requester: name,
    email: email,
    phone: phone,
    issuetype: '',
    company: '',
    description: '',
    priority: '',
    file: null,
  }
  const validationSchema = Yup.object({}).shape({
    requester: Yup.string()
      .required('Requester cannot be blank')
      .min(4, 'Requester must be more than 4 characters')
      .max(40, 'Requester must be less than 40 characters'),
    email: Yup.string()
      .required('Email cannot be blank')
      .email('Invalid email')
      .min(3, 'Email must be more than 3 characters')
      .max(50, 'Email must be less than 50 characters'),
    phone: Yup.string().matches(phoneNumberRegex, 'Invalid phone format'),
    issuetype: Yup.string().required('Issue type cannot be blank'),
    priority: Yup.string().required('Priority cannot be blank'),
    company: Yup.string()
      .required('Company cannot be blank')
      .min(3, 'Company must be more than 3 characters')
      .max(30, 'Company must be less than 30 characters'),
    description: Yup.string()
      .required('Description cannot be blank')
      .min(25, 'Description must be more than 25 characters')
      .max(2000, 'Description must be less than 2000 characters'),
    file: getFileTrue
      ? ''
      : Yup.mixed()
          .test(
            'fileSize',
            'File size cannot be more than 5MB',
            (fileDetails) => {
              if (!fileDetails) return true
              return fileDetails?.file?.size <= MAX_FILE_BYTE_SIZE
            }
          )
          .test(
            'fileType',
            'File can be either json, text, doc, html, pdf, png or jpg',
            (fileData) => {
              if (!fileData) return true
              return VALID_FILE_FORMATS.includes(fileData?.type)
            }
          ),
  })

  const sendIncidentData = async (data) => {
    try {
      const { file, ...restData } = data
      const transformedFile = file
        ? {
            name: file?.name,
            type: file?.type,
            base64: file?.base64,
          }
        : null
      const postData = {
        ...restData,
        file: transformedFile,
      }
      postdata()
      async function postdata() {
        try {
          const request = await fetch(API_URL, {
            method: 'post',
            body: JSON.stringify(postData),
            headers: {
              'Content-Type': 'text/plain',
              requester: JSON.stringify(
                window.localStorage.getItem('requester')
              ),
            },
            credentials: 'include',
          })
          if (request.status === 200 || request.status === 201) {
        var data = await request.text()        
        data = JSON.parse(data);  
        if (data.status==="0")
          {
            setFileTrue(true)
            toast.error(data.error_message, {
              toastId: 'failure2',
            }) 
          }
          else if (data.status.toUpperCase()==='ERROR')
            {
              setFileTrue(true)
              toast.error(data.error_message.split(';')[0].replace('priority of 5', 'priority of Low'), {
                toastId: 'failure3',
              }) 
            }
          else{
            
            setFileTrue(true)
            toast.success(
              `Your report is sent. Incident : ${data?.display_value}`,
              {
                toastId: 'success1',
              }
            )
          } 
        }
        else {
          throw new Error('Failed to create an incident ticket')
        }      
        }
        catch(error) {
          setFileTrue(true)
          console.log(error)
          toast.error('Failed to create an incident ticket', {
            toastId: 'failure1',
          })
        }
      }      
    } catch (error) {
      console.log(error)
      toast.error('Failed to create an incident ticket', {
        toastId: 'failure1',
      })
    }
  }
  
  const handleChangeddl = (event) => {
    console.log("Genre Changed")
  }
  return (
    <div className={styles.form}>
      <Formik
        initialValues={intialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          setSubmitting(true)
          await sendIncidentData(values)
          setSubmitting(false)
          setResetFile((prev) => !prev)
          resetForm(intialValues)
        }}
        initialTouched={false}
      >
        {(formik) => {
          const {
            values,
            touched,
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            handleReset,
            setFieldValue,
            isSubmitting,
          } = formik
          return (
            <form onSubmit={handleSubmit}>
              <TextBox
                required={true}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.requester}
                id="requester"
                label="requested by"
                errorText={touched.requester && errors.requester}
                width="100%"
                maxLength={40}
              />
              <TextBox
                required={true}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                id="email"
                label="email"
                errorText={touched.email && errors.email}
                width="100%"
                maxLength={50}
              />
              <TextBox
                onChange={(e) => {
                  const inputElement = e.target
                  const inputValue = inputElement.value
                  const inputCursorPosition = inputElement.selectionStart

                  const formattedValue = inputValue
                    .replace(/\D/g, '')
                    .match(/(\d{0,3})(\d{0,3})(\d{0,4})/)

                  if (formattedValue) {
                    let formattedPhone = ''

                    if (formattedValue[1].length > 0) {
                      formattedPhone += `(${formattedValue[1]}`
                    }

                    if (formattedValue[2].length > 0) {
                      formattedPhone += `) ${formattedValue[2]}`
                    }

                    if (formattedValue[3].length > 0) {
                      formattedPhone += `-${formattedValue[3]}`
                    }

                    inputElement.value = formattedPhone
                    setFieldValue('phone', formattedPhone)

                    // Preserve cursor position
                    const cursorPosition =
                      inputCursorPosition +
                      (formattedPhone.length - inputValue.length)
                    inputElement.setSelectionRange(
                      cursorPosition,
                      cursorPosition
                    )
                  } else {
                    inputElement.value = ''
                    setFieldValue('phone', '')
                  }
                }}
                onBlur={handleBlur}
                value={values.phone}
                id="phone"
                label="phone"
                name="phone"
                placeholder="(555) 555-1234"
                maxLength={14}
                errorText={touched.phone && errors.phone}
                width="100%"
              />
              <DropDownList
                id="issuetype"
                label="Issue Type"
                required={true}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.issuetype}
                errorText={touched.issuetype && errors.issuetype}
                width="100%"
              >
                {IssueType.map((type) => {
                  return (
                    <DropDownList.Item value={type.value} key={type.value}>
                      {type.text}
                    </DropDownList.Item>
                  )
                })}
              </DropDownList>
              <DropDownList
                id="priority"
                label="Priority"
                required={true}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.priority}
                errorText={touched.priority && errors.priority}
                width="100%"
              >
                {Priority.map((priority) => {
                  return (
                    <DropDownList.Item
                      key={priority.value}
                      value={priority.value}
                    >
                      {priority.text}
                    </DropDownList.Item>
                  )
                })}
              </DropDownList>
              <TextBox
                required={true}
                onChange={handleChange}
                value={values.company}
                id="company"
                label="company"
                onBlur={handleBlur}
                errorText={touched.company && errors.company}
                width="100%"
                maxLength={50}
              />

              <TextArea
                required={true}
                onChange={handleChange}
                value={values.description}
                onBlur={handleBlur}
                id="description"
                label="description"
                type={'textarea'}
                errorText={touched.description && errors.description}
                width="100%"
                maxLength={2000}
              />

              <div className={styles.input_file}>
                <Label error={!getFileTrue && touched.file && errors.file}>
                  Attachments
                </Label>
                <FileBase64
                  key={resetFile}
                  multiple={false}
                  onDone={(files) => {
                    setFieldValue('file', files)
                    files !== null ? setFileTrue(false) : setFileTrue(true)
                  }}
                  // onDone={(files) => setFieldValue('file', files) setFileTrue(false)}
                />
                {values.file && (
                  <span>
                    <MdDeleteForever
                      color="red"
                      cursor="pointer"
                      title="Delete file"
                      onClick={() => {
                        setResetFile((prev) => !prev)
                        setFieldValue('file', null)
                        setFileTrue(true)
                      }}
                    />
                  </span>
                )}

                {!getFileTrue && (
                  <p className={styles.error}>{touched.file && errors.file} </p>
                )}
              </div>

              <ButtonList className={styles.btn_grp} spacing={2}>
                <Button
                  className={styles.i_cancel_btn}
                  tabIndex={0}
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  className={styles.i_submit_btn}
                  tabIndex={2}
                  type="submit"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? 'Submiting...' : 'Submit'}
                </Button>
              </ButtonList>
            </form>
          )
        }}
      </Formik>
      <ToastContainer
        position="bottom-right"
        autoClose={6000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  )
}

export default IncidentForm
