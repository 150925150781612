import React from 'react'
import Highcharts from 'highcharts'
import Chart from '../../components/Highcharts/Chart'
import { CurrentEpiWeek, CurrentWeek ,PreviousWeek} from './transformDataForGrid'
import getLineChartOptions from '../../components/LineChart/lineChartOptions'
import styles from './SeasonsChart.module.scss'
import dataSeasons from './Seasons'

const SeasonsChart = ({ data, identifier, selectedOptions,dataForecast ,weeks}) => {
  
  let transformedData = new Map()
  Object.entries(data).forEach(([epiWeekDate, epiWeekData]) => {
    let weekMap = epiWeekData[identifier]
    let season = epiWeekData[identifier].epi_season
    let week = epiWeekData[identifier].epi_period_week
    transformedData[season] = {
      ...transformedData[season],
      [week]: { ...weekMap, epi_week: epiWeekDate },
    }
  })
  // TO-DO revisit the code if we can send seasons
  // for chart based on api response

  let transformedDataForecast = new Map()
  Object.entries(dataForecast).forEach(([epiWeekDate, epiWeekData]) => {
    let weekMap = epiWeekData[identifier]
    let season = epiWeekData[identifier].epi_season
    let week = epiWeekData[identifier].epi_period_week
    transformedDataForecast[season] = {
      ...transformedDataForecast[season],
      [week]: { ...weekMap, epi_week: epiWeekDate },
    }
  })

  const data_forecast_current = transformedDataForecast['2024-25']
  const data_2024_25 = transformedData['2024-25']
  const data_2023_24 = transformedData['2023-24']
  const data_2022_23 = transformedData['2022-23']
  const data_2021_22 = transformedData['2021-22']
  const data_2020_21 = transformedData['2020-21']
  const data_2019_20 = transformedData['2019-20']
  const data_2018_19 = transformedData['2018-19']
  const data_2017_18 = transformedData['2017-18']
  let currentWeek = PreviousWeek()
  let currentWeekIndex = 0
  const currentWeekMinus3Weeks = new Date(CurrentEpiWeek())
  currentWeekMinus3Weeks.setDate(currentWeekMinus3Weeks.getDate() - 21)
  const chart_2024_25 = []
  const chart_2023_24 = []
  const chart_2022_23 = []
  const chart_2021_22 = []
  const chart_2020_21 = []
  const chart_2019_20 = []
  const chart_2018_19 = []
  const chart_2017_18 = []
  const chart_2016_17 = []
  const chart_2015_16 = []
  const chart_2014_15 = []
  const forecast_values = []
  const percent95 = []
  const high_line = []
  const chart_forecast = []
  const chart_percent95 = []

  weeks.forEach((week, i) => {
    if (week === currentWeek) {
      currentWeekIndex = i
    }
    if (data_2019_20 !== undefined && data_2019_20 !== null) {
      if (data_2019_20[week]) {
        // chart_date.push(data_2019_20[week].week_ending)
        high_line.push(
          data_2019_20[week]
            ? {
                y: data_2019_20[week].baseline,
                marker: { enabled: week % 4 === 0 ? true : false },
                week_ending: data_2019_20[week].week_ending,
              } //data_2019_20[week].high95 //high_point
            : high_line.length > 0
            ? {
                y: high_line[i - 1].y,
                marker: { enabled: week % 4 === 0 ? true : false },
                week_ending: data_2019_20[week].week_ending,
              } // high_line[i - 1]
            : null
        )
      } else {
        high_line.push(null)
      }
    }
    if (data_2023_24 !== undefined && data_2023_24 !== null) {
      if (data_2023_24[week]) {
        chart_2023_24.push(
          data_2023_24[week]
            ? {
                y: data_2023_24[week].data_value,
                marker: { enabled: week % 4 === 0 ? true : false },
                week_ending: data_2023_24[week].week_ending,
              } //data_2021_22[week].data_value //actual_value
            : chart_2023_24.length > 0
            ? {
                y: chart_2023_24[i - 1].y,
                marker: { enabled: week % 4 === 0 ? true : false },
                week_ending: data_2023_24[week].week_ending,
              }
            : null
        )
      } else {
        chart_2023_24.push(null)
      }
    }
    if (data_2022_23 !== undefined && data_2022_23 !== null) {
      if (data_2022_23[week]) {
        chart_2022_23.push(
          data_2022_23[week]
            ? {
                y: data_2022_23[week].data_value,
                marker: { enabled: week % 4 === 0 ? true : false },
                week_ending: data_2022_23[week].week_ending,
              } //data_2021_22[week].data_value //actual_value
            : chart_2022_23.length > 0
            ? {
                y: chart_2022_23[i - 1].y,
                marker: { enabled: week % 4 === 0 ? true : false },
                week_ending: data_2022_23[week].week_ending,
              }
            : null
        )
      } else {
        chart_2022_23.push(null)
      }
    }
    if (data_2021_22 !== undefined && data_2021_22 !== null) {
      if (data_2021_22[week]) {
        chart_2021_22.push(
          data_2021_22[week]
            ? {
                y: data_2021_22[week].data_value,
                marker: { enabled: week % 4 === 0 ? true : false },
                week_ending: data_2021_22[week].week_ending,
              } //data_2021_22[week].data_value //actual_value
            : chart_2021_22.length > 0
            ? {
                y: chart_2021_22[i - 1].y,
                marker: { enabled: week % 4 === 0 ? true : false },
                week_ending: data_2021_22[week].week_ending,
              }
            : null
        )
      } else {
        chart_2021_22.push(null)
      }
    }
    if (data_2020_21 !== undefined && data_2020_21 !== null) {
      if (data_2020_21[week]) {
        chart_2020_21.push(
          data_2020_21[week]
            ? {
                y: data_2020_21[week].data_value,
                marker: { enabled: week % 4 === 0 ? true : false },
                week_ending: data_2020_21[week].week_ending,
              } //data_2020_21[week].data_value //actual_value
            : chart_2020_21.length > 0
            ? {
                y: chart_2020_21[i - 1].y,
                marker: { enabled: week % 4 === 0 ? true : false },
                week_ending: data_2020_21[week].week_ending,
              } //chart_2020_21[i - 1]
            : null
        )
      } else {
        chart_2020_21.push(null)
      }
    }
    if (data_2019_20 !== undefined || data_2019_20 !== null) {
      if (data_2019_20[week]) {
        chart_2019_20.push(
          data_2019_20[week]
            ? {
                y: data_2019_20[week].data_value,
                marker: { enabled: week % 4 === 0 ? true : false },
                week_ending: data_2019_20[week].week_ending,
              } //data_2019_20[week].data_value
            : chart_2019_20.length > 0
            ? {
                y: chart_2019_20[i - 1].y,
                marker: { enabled: week % 4 === 0 ? true : false },
                week_ending: data_2019_20[week].week_ending,
              } //chart_2019_20[i - 1]
            : null
        )
      } else {
        chart_2019_20.push(null)
      }
    }

    if (data_2018_19 !== undefined && data_2018_19 !== null) {
      if (data_2018_19[week]) {
        chart_2018_19.push(
          data_2018_19[week]
            ? {
                y: data_2018_19[week].data_value,
                marker: { enabled: week % 4 === 0 ? true : false },
                week_ending: data_2018_19[week].week_ending,
              } //data_2018_19[week].data_value
            : chart_2018_19.length > 0
            ? {
                y: chart_2018_19[i - 1].y,
                marker: { enabled: week % 4 === 0 ? true : false },
                week_ending: data_2018_19[week].week_ending,
              } //chart_2018_19[i - 1]
            : null
        )
      } else {
        chart_2018_19.push(null)
      }
    }
    if (data_2017_18 !== undefined && data_2017_18 !== null) {
      if (data_2017_18[week]) {
        chart_2017_18.push(
          data_2017_18[week] !== undefined
            ? {
                y: data_2017_18[week].data_value,
                marker: { enabled: week % 4 === 0 ? true : false },
                week_ending: data_2017_18[week].week_ending,
              } //data_2017_18[week].data_value
            : chart_2017_18.length > 0
            ? {
                y: chart_2017_18[i - 1].y,
                marker: { enabled: week % 4 === 0 ? true : false },
                week_ending:
                  data_2017_18[week] === null ||
                  data_2017_18[week] === undefined
                    ? 'NA'
                    : data_2017_18[week].week_ending,
              } //chart_2017_18[i - 1]
            : null
        )
      } else {
        chart_2017_18.push(null)
      }
    }

    if (data_2024_25 !== undefined && data_2024_25 !== null) {
      if (data_2024_25[week]) {
        if (new Date(data_2024_25[week].epi_week) <= currentWeekMinus3Weeks) {
          //chart_2022_23.push(data_2022_23[week].data_value)

          let obj = {
            y: data_2024_25[week].data_value,
            marker: { enabled: week % 4 === 0 ? true : false },
            week_ending: data_2024_25[week].week_ending,
          }
          chart_2024_25.push(obj)
          if (
            new Date(data_2024_25[week].epi_week).getDate() ===
              currentWeekMinus3Weeks.getDate() &&
            new Date(data_2024_25[week].epi_week).getMonth() ===
              currentWeekMinus3Weeks.getMonth()
          ) {
            let obj1 = {
              y: data_2024_25[week].data_value,
              marker: { enabled: week % 4 === 0 ? true : false },
              week_ending: data_2024_25[week].week_ending,
            }

            forecast_values.push(obj1)

            let obj11 = {
              low: data_2024_25[week].data_value,
              high: data_2024_25[week].data_value,
              marker: { enabled: false },
              week_ending: data_2024_25[week].week_ending,
            }
            percent95.push(obj11)
          } else {
            forecast_values.push(null)
            let test1 = []
          
            test1.push(null)
            test1.push(null)
            percent95.push(test1)
          }
        } else if (
          new Date(data_2024_25[week].epi_week).getTime() ===
          currentWeekMinus3Weeks.getTime()
        ) {
          let obj = {
            y: data_2024_25[week].data_value,
            marker: { enabled: week % 4 === 0 ? true : false },
            week_ending: data_2022_23[week].week_ending,
          }
          chart_2024_25.push(obj)
      
          let obj2 = {
            y: data_2024_25[week].data_value,
            marker: { enabled: week % 4 === 0 ? true : false },
            week_ending: data_2022_23[week].week_ending,
          }
       
          forecast_values.push(obj2)
        
          let obj11 = {
            low: data_2024_25[week].low95,
            high: data_2024_25[week].high95,
            marker: { enabled: false },
            week_ending: data_2024_25[week].week_ending,
          }
          percent95.push(obj11)
        } else {
         
          let obj22 = {
            low: data_2024_25[week].low95,
            high: data_2024_25[week].high95,
            marker: { enabled: false },
            week_ending: data_2024_25[week].week_ending,
          }
          percent95.push(obj22)
          let obj3 = {
            y: data_2024_25[week].data_value,
            marker: { enabled: week % 4 === 0 ? true : false },
            week_ending: data_2024_25[week].week_ending,
          }

          forecast_values.push(obj3)

          chart_2024_25.push(null)
        }
      } else {
        chart_2024_25.push(null)
        forecast_values.push(null)
        let test = []
        // test.push(week)
        test.push(null)
        test.push(null)
        percent95.push(test)
      }
    }
    //forecast data
    if (data_forecast_current !== undefined && data_forecast_current !== null) {
      if (data_forecast_current[week]) {
       
        chart_forecast.push(
          data_forecast_current[week]
            ? {
                y: data_forecast_current[week].data_value,
                marker: { enabled: week % 4 === 0 ? true : false },
                week_ending: data_forecast_current[week].week_ending,
              } 
            : chart_forecast.length > 0
            ? {
                y: chart_forecast[i - 1].y,
                marker: { enabled: week % 4 === 0 ? true : false },
                week_ending: data_forecast_current[week].week_ending,
              } 
            : null
        )
chart_percent95.push( data_forecast_current[week]
  ? 
    {
      low: data_forecast_current[week].low95,
      high: data_forecast_current[week].high95,
      marker: { enabled: false },
      week_ending: data_forecast_current[week].week_ending,
    }
  : chart_percent95.length > 0
  ? {
      low: data_forecast_current[i-1].low,
      high:  data_forecast_current[i-1].high,
      marker: { enabled: false },
      week_ending: data_forecast_current[week].week_ending,
      
    } 
  : null)
        
      } else {
        chart_percent95.push(null)
        chart_forecast.push(null)
      }
    }
  })

  let SET2024_2025 = false,
    SET2023_2024 = false,
    SET2022_2023 = false,
    SET2021_2022 = false,
    SET2020_2021 = false,
    SET2019_2020 = false,
    SET2018_2019 = false,
    SET2017_2018 = false,
    SET2016_2017 = false,
    SET2015_2016 = false,
    SET2014_2015 = false,
    SETINTERVAL = false

  if (selectedOptions.length > 0) {
    SET2024_2025 = selectedOptions.some((el) => el.label === '2024-25')
    SET2023_2024 = selectedOptions.some((el) => el.label === '2023-24')
    SET2022_2023 = selectedOptions.some((el) => el.label === '2022-23')
    SET2021_2022 = selectedOptions.some((el) => el.label === '2021-22')
    SET2020_2021 = selectedOptions.some((el) => el.label === '2020-21')
    SET2019_2020 = selectedOptions.some((el) => el.label === '2019-20')
    SET2018_2019 = selectedOptions.some((el) => el.label === '2018-19')
    SET2017_2018 = selectedOptions.some((el) => el.label === '2017-18')
    SET2016_2017 = selectedOptions.some((el) => el.label === '2016-17')
    SET2015_2016 = selectedOptions.some((el) => el.label === '2015-16')
    SET2014_2015 = selectedOptions.some((el) => el.label === '2014-15')
    if(selectedOptions.length === 1 && selectedOptions[0].label===dataSeasons[0].label)
    {SETINTERVAL = true}
    else{SETINTERVAL = false}
  }
  else{ SETINTERVAL = true}

  const lineChartData = [
    {
      name: 'Baseline',
      data: high_line,
      dashStyle: 'LongDash',
      lineWidth: 1,
      color: '#7D7F81', //'red',
      showInLegend: true,
    },
    {
      name: '2024-2025',
      data: chart_2024_25,
      dashStyle: 'solid',
      color: Highcharts.getOptions().colors[10],
      visible: SET2024_2025,
      showInLegend: SET2024_2025,
    },
    {
      name: '2023-2024',
      data: chart_2023_24,
      dashStyle: 'solid',
      color: Highcharts.getOptions().colors[9],
      visible: SET2023_2024,
      showInLegend: SET2023_2024,
    },
    {
      name: '2022-2023',
      data: chart_2022_23,
      dashStyle: 'solid',
      color: Highcharts.getOptions().colors[8],
      visible: SET2022_2023,
      showInLegend: SET2022_2023,
    },
    {
      name: '2021-2022',
      data: chart_2021_22,
      dashStyle: 'solid',
      color: Highcharts.getOptions().colors[7],
      visible: SET2021_2022,
      showInLegend: SET2021_2022,
    },
    {
      name: '2020-2021',
      data: chart_2020_21,
      dashStyle: 'solid',
      color: Highcharts.getOptions().colors[0],
      visible: SET2020_2021,
      showInLegend: SET2020_2021,
    },
    {
      name: '2019-2020',
      data: chart_2019_20,
      dashStyle: 'solid',
      color: Highcharts.getOptions().colors[1],
      visible: SET2019_2020,
      showInLegend: SET2019_2020,
    },
    {
      name: '2018-2019',
      data: chart_2018_19,
      dashStyle: 'solid',
      color: Highcharts.getOptions().colors[2],
      visible: SET2018_2019,
      showInLegend: SET2018_2019,
    },
    {
      name: '2017-2018',
      data: chart_2017_18,
      dashStyle: 'solid',
      color: Highcharts.getOptions().colors[3],
      visible: SET2017_2018,
      showInLegend: SET2017_2018,
    },
    {
      name: 'Forecast',
      data: chart_forecast,
      dashStyle: 'dot',
      color: '#15A796', 
      // visible: true,
      showInLegend: SETINTERVAL,
      visible: SETINTERVAL,
    },
    {
      name: 'Forecast',
      data: forecast_values,
      dashStyle: 'dot',
      color: '#15A796', 
      visible: !SETINTERVAL,
      showInLegend: !SETINTERVAL,
    },
   
    {
      name: 'Prediction Intervals',
      data: chart_percent95,
      type: 'arearange',
      lineWidth: 0,
      linkedTo: ':previous',
      color: '#C7EAE6',
      fillOpacity: 0.25,
      zIndex: 0,
      showInLegend: SETINTERVAL,
      visible: SETINTERVAL,
      marker: {
        enabled: true,
        symbol: 'square',
      },
    },
    {
      name: '2016-2017',
      data: chart_2016_17,
      dashStyle: 'solid',
      color: Highcharts.getOptions().colors[4],
      visible: SET2016_2017,
      showInLegend: SET2016_2017,
    },
    {
      name: '2015-2016',
      data: chart_2015_16,
      dashStyle: 'solid',
      color: Highcharts.getOptions().colors[5],
      visible: SET2015_2016,
      showInLegend: SET2015_2016,
    },
    {
      name: '2014-2015',
      data: chart_2014_15,
      dashStyle: 'solid',
      color: Highcharts.getOptions().colors[6],
      visible: SET2014_2015,
      showInLegend: SET2014_2015,
    },
  ]

  const lineOptions = getLineChartOptions(
    lineChartData,
    weeks,
    currentWeekIndex,
    currentWeek,
    '',
    SETINTERVAL
  )
  return (
    lineOptions && (
      <div className={styles.chartDiv}>
        <div className={styles.container}>
          <div style={{ width: '100%', textAlign: 'center' }}>
            <Chart
             
              chartOptions={lineOptions}
              height={'350px'}
            />
          </div>
          <div className={styles.bottomText}>
            {' '}
            <span>
            Interact with the graph key to show, hide or highlight specific lines.
            </span>
          </div>
        </div>
      </div>
    )
  )
}

export default SeasonsChart