import React from 'react'
import { Redirect } from 'react-router-dom'
import { useAuthDispatch } from '../../components/AuthProvider'
import ErrorLogs from '../../components/ErrorBoundary/errorlogs'
import { useAuthState } from '../../components/AuthProvider'
import { clearAllCookies } from './utils'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
const SignOut = () => {
  const { user } = useAuthState()
  const history = useHistory()
  const { clearSession } = useAuthDispatch()

  React.useEffect(() => {
    clearSession()
    clearAllCookies()
    localStorage.clear()

    history.replace('/')
  }, [clearSession])

  return <h1>Signing out...</h1>
}

export default SignOut
