import React from "react";
import Content from "../../components/Content";
import Layout from "../../components/Layout";
import styles from './Unauthorized.module.scss'
import reg from '../../assets/images/Registration_Illustration.gif'
import { SUPPORT_EMAIL } from '../../utils/constants'
const UnAuthorized = () => {

  return (
  <Layout>
  <Content>
    <div className={styles.base}>
      <div className={styles.maindivFormat}>
        <div style={{ width: '60%' }}>
          <img
            src={reg}
            alt="arrow-down"
            style={{ height: '550px', width: '100%', marginBottom: '-4px' }}
          ></img>
        </div>

        <div style={{ width: '40%', marginTop: '48px' }}>
          <div className={styles.formheader}>
           401: Unauthorized Access
          </div>   
          <div className={styles.support}>
            Kindly contact Infectious Disease Platform team.<br></br>
            <div>Email Id: <a className={styles.link} href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a></div>
            <div>Technology Group: <a target="_blank" rel="noreferrer"  className={styles.link} href="https://helpdesk.uhg.com/">IDP - RSV - SPT</a></div>
          </div>      
        </div>
      </div>
    </div>
  </Content>
</Layout>
  )

  }

export default UnAuthorized;
