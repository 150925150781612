import { React, useState, useEffect } from 'react'
import styles from './GenderAgePanel.module.scss'
import ColumnChart from '../../../components/ColumnChartVul'
import GenderAge from '../../../components/ColumnChartVul/GenderAgeDetails'
import { useGeoFetch,useGeoState } from '../../../components/GeoProvider'
import ErrorMessage from '../../../components/Layout/ErrorMessage'
import { useAuthState } from '../../../components/AuthProvider'
import Search from '../../../components/Search'
import getVectorImage from '../../../utils/getVectorImage'
import ApiAgeGender from './ApiAgeGender'
const GenderAgeView = ({ geo_type, geo_id, 
  data_type,
  setSearchLocationValue,
  searchLocation,
  setSearchLocationTextMethod,
  searchLocationText }) => {
  const [searchCriteria, setSearchCriteria] = useState([])
  const {locationName,displayDate} =useGeoState()

  const comparisonStat=((searchLocation===''||searchLocation===undefined)?false:true)
  const { user } = useAuthState()
  const [genderAge, setGenderAge] = useState()
  const [genderAge1, setGenderAge1] = useState()
  const [errorCode, setErrorCode] = useState()
  const [errorMessage, setErrorMessage] = useState()
  const [errorSubject, setErrorSubject] = useState()
  const [errorOccured, seterrorOccured] = useState(false)
  var genderendpoint =data_type+'/getagegender'
  const [activeCbsa, setActiveCbsa] = useState()
  const { data: activeCbsasData, error: activeCbsasDataError } = useGeoFetch(
    '',
    '',
    'vulnerability',
    'searchlocations/vulnerability?geo_type=MSA'
  )

  useEffect(() => {
    if (activeCbsasData !== null && activeCbsasData !== undefined) {
      if (activeCbsasData.response_code === 200) {
        setActiveCbsa(activeCbsasData.response_data)
      
       }
    }
  }, [activeCbsasData])
  const { data: genderAgeData, error: genderAgeDataError } = useGeoFetch(
    '',
    '',
    genderendpoint,
    geo_type + '/' + geo_id
  )

  useEffect(() => {
    if (genderAgeData !== null && genderAgeData !== undefined) {
      if (genderAgeData.response_code === 200) {       
        setGenderAge(genderAgeData.response_data)

      } else {
        setErrorCode(genderAgeData.response_code)
        setErrorMessage(genderAgeData.response_message)
        setErrorSubject(genderAgeData.response_subject)
        seterrorOccured(true)
      }
    }
  }, [genderAgeData])

 
  const setAgeDataSecond = (data) => {
    setGenderAge1(data);
  };
  if ( errorOccured) {
    return (
      <ErrorMessage
        errorCode={errorCode}
        errorMessage={''}
        errorSubject={errorSubject}
        errorDetails={errorMessage+' for Age & Gender Graph'}
      />
    )
  }

  return  (
    <div className={styles.base}>
      <div className={styles.maindivFormat}>
      <div className={styles.headerDivSearch}> 
         
         <div className={styles.locationLegend}>{searchLocationText!=="" && searchLocationText!==undefined&&
         <>
          <span>{getVectorImage('rectangle_barchart', '24px', '24px')}</span>
           <span>
           {locationName}
           </span>
           <span>{getVectorImage('line_barchart', '30px', '10px')}</span>
           <span>{searchLocationText}</span>
           </>
           }
          </div>
         {geo_type!=="NAT"?<div className={styles.sideFilter}>
      
      <div   className={styles.searchBox}>
          {activeCbsa !== undefined && (
        <Search
               criteria={searchCriteria}
               onChange={setSearchCriteria}
               entity={'VulnerabilityEvent'}
               placeholder={'Compare State/MSA'}
               activeMetros={activeCbsa}
               showStates={geo_type==='STATE'?true:false}
               showMSAs={geo_type==='MSA'?true:false}
               setSearchLocationValue={setSearchLocationValue}
               setSearchLocationTextMethod={setSearchLocationTextMethod}
            />
          )}
            </div>
        </div>:null}
       </div>
      <div className={styles.headerDiv}> 
          <div className={styles.captionFormat}>
          Age & Gender
          <p className={styles.subCaptionFormat}>
          Age and gender of individuals with viral respiratory conditions during the last month. 
          
          
            </p>
            
          </div>
         
        </div>
     
        <div>
          {genderAge !== undefined && locationName!==undefined && searchLocationText!==undefined ? (
            <>
             {comparisonStat===true?
             <>
             <ApiAgeGender geo_type={geo_type} geo_id={searchLocation} setGenderAge1={setAgeDataSecond} />
             <ColumnChart 
             data={genderAge1!==undefined&&[genderAge,genderAge1]} 
             chartName="genderAgechart"
            renderType="dashboard" 
             multiColumn={comparisonStat} 
             locationName={locationName} 
             searchLocationText={searchLocationText}
             displayDate={displayDate}
             />
            {genderAge1!==undefined&& 
            <GenderAge 
             data={[genderAge,genderAge1]} 
             multiColumn={comparisonStat}
             />}
            
              </>:
              <>
              <ColumnChart data={[genderAge]} 
             chartName="genderAgechart"
              renderType="dashboard" 
             multiColumn={comparisonStat} 
             locationName={locationName} 
             searchLocationText={searchLocationText}
             displayDate={displayDate}
             />
             <GenderAge 
              data={[genderAge]} 
              multiColumn={comparisonStat}/></>
             }

            </>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default GenderAgeView
