import { React, useState, useEffect } from 'react'
import styles from './Estimates.module.scss'
import { useGeoFetch,useGeoState } from '../../../components/GeoProvider'
import ErrorMessage from '../../../components/Layout/ErrorMessage'
import { useAuthState } from '../../../components/AuthProvider'
import ReactSwitch from 'react-switch'
import ColumnChart from '../../../components/ColumnChartVul'
import EstimatesTable from './EstimatesTable'
import Spinner from '../../../components/Loader/Spinner'
import ApiEstimate from './ApiEstimate'
const Estimates = ({ geo_type, geo_id,searchLocation,searchLocationText }) => {
  const { locationName ,displayDate} = useGeoState()
  const [showTable, setshowTable]=useState(false)
  const comparisonStat=((searchLocation===''||searchLocation===undefined)?false:true)
  const { user } = useAuthState()
  const [genderAge1, setGenderAge1] = useState()
  const [genderAge, setGenderAge] = useState()
  const [errorCode, setErrorCode] = useState()
  const [errorMessage, setErrorMessage] = useState()
  const [errorSubject, setErrorSubject] = useState()
  const [errorOccured, seterrorOccured] = useState(false)
   const handleChange = (val) => {
    setshowTable(val)
   }
   const { data: genderAgeData, error: genderAgeDataError } = useGeoFetch(
    '',
    '',
    'vulnerability',
    'getestimates/'+geo_type + '/' + geo_id
  )

  useEffect(() => {
    if (genderAgeData !== null && genderAgeData !== undefined) {
      if (genderAgeData.response_code === 200) {        
        setGenderAge(genderAgeData.response_data)

      } else {
        setErrorCode(genderAgeData.response_code)
        setErrorMessage(genderAgeData.response_message)
        setErrorSubject(genderAgeData.response_subject)
        seterrorOccured(true)
      }
    }
  }, [genderAgeData])
  if (errorOccured) {
    return (
      <ErrorMessage
        errorCode={errorCode}
        errorMessage={errorMessage}
        errorSubject={errorSubject}
        errorDetails={errorMessage+" for Estimate Graph."}
      />
    )
  }
  const setAgeDataSecond = (data) => {
    setGenderAge1(data);
  };
  return  (
    <div className={styles.base}>
      <div className={styles.maindivFormat}>
        <div className={styles.headerDiv}> 
          <div className={styles.captionFormat}>
          Social Vulnerability Index (SVI) Estimates
            
            <div className={styles.subCaptionFormat}>
              <span>SVI estimates of the overall population in the selected region. </span>
              <div style={{display:'flex',marginTop:'10px'}}>
               <span>
              {' '}
              <svg
                height="20"
                width="20"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                fill="#00396c"
                focusable="false"
                aria-hidden="true"
              >
                <path d="M11 7h2v2h-2zm1 10c.55 0 1-.45 1-1v-4c0-.55-.45-1-1-1s-1 .45-1 1v4c0 .55.45 1 1 1zm0-15C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"></path>
              </svg>
            
            </span>{' '}
            &nbsp;&nbsp;
            <div
              style={{ '&::WebkitScrollbar': { width: 0, height: 0 } }}
             
            >
              {' '}
              <a href='https://www.census.gov/programs-surveys/acs/news/data-releases/2023/release.html#oneyear' target='_blank'>
              ACS</a>-
              <a href='https://atsdr.cdc.gov/place-health/media/pdfs/2025/01/SVI2022Documentation.pdf' target='_blank'>
              SVI</a> 
              {' '}(Jan 1, 2023-Dec 31,2023)
            </div>
            </div>
            </div>
          </div>
          <div className={styles.sideFilter}>
          <div
            style={{
              display: 'flex',
              width: '65%',
              justifyContent: 'flex-end',
              paddingBottom: '16px',
              paddingTop: '16px',
            }}
          >
            <span className={styles.showOnMap}>{showTable?'Table View':'Chart View'}</span>
            <ReactSwitch
              onColor="#196ECF"
              offColor="#7D7F81"
              height={20}
              width={40}
              uncheckedIcon={false}
              checkedIcon={false}
              handleDiameter={15}
              checked={showTable}
              onChange={handleChange}
            />
          </div>
          </div>
        </div>
        
        {showTable?
        <div className={styles.tableView}>
           {genderAge !== undefined && locationName!==undefined &&searchLocationText!==undefined? (
           <>
           {comparisonStat===true? <>
              <EstimatesTable data={genderAge1!==undefined&&[genderAge,genderAge1]} multiColumn={comparisonStat}/>
              </>: <EstimatesTable data={[genderAge]} multiColumn={comparisonStat}/>
              }
             
           </>
          
           ): null}
        </div>        : 
        <div>
          
          {genderAge !== undefined && locationName!==undefined &&searchLocationText!==undefined? (
            <>
            {comparisonStat===true?
               <>
              <ApiEstimate geo_type={geo_type} geo_id={searchLocation} setGenderAge1={setAgeDataSecond}/>
              <div style={{display:'flex'}}>    
              <ColumnChart data={genderAge1!==undefined&&[genderAge,genderAge1]} chartName="estimateChart" 
              renderType="dashboard" 
              multiColumn={comparisonStat} 
              locationName={locationName}
              searchLocationText={searchLocationText}
              displayDate={displayDate}
               />
              </div>
              </>:<div style={{display:'flex'}}>    
              <ColumnChart data={[genderAge]} chartName="estimateChart" 
              renderType="dashboard" 
              multiColumn={comparisonStat} 
              locationName={locationName}
              searchLocationText={searchLocationText}
              displayDate={displayDate}
               />
              </div>
              }
            </>
          ) : <Spinner/>}
        </div>
        }
      </div>
    </div>
    
  ) 
}

export default Estimates
