import { React, useState, useEffect } from 'react'
import styles from './RespiratoryGraph.module.scss'
import { useGeoFetch,useGeoState } from '../../../components/GeoProvider'
import DrillDownChart from '../../../components/DrillDownChart'
import drilldownFilterOptions from './drilldownFilterOptions'
import SelectList from '../../../components/SelectList'
import ErrorMessage from '../../../components/Layout/ErrorMessage'
import Spinner from '../../../components/Loader/Spinner'
const AcuityGraph = ({ geo_type, geo_id }) => {
  
  const [errorCode, setErrorCode] = useState()
  const [errorMessage, setErrorMessage] = useState()
  const [errorSubject, setErrorSubject] = useState()
  const [errorOccured, seterrorOccured] = useState(false)
  const [getAcuityData, setAcuityData] = useState()
  const [getDrillDownData, setDrillDownData] = useState()
  const { locationName,displayDate } = useGeoState()
  const [drillDownSelectedValue,setDrillDownSelectedValue]=useState('')
  const setDrillDownSelectedValueMethod=(data)=>{setDrillDownSelectedValue(data)}
  const { data: acData, error: acDataError } = useGeoFetch(
    '',
    '',
    'vulnerability',
    'getdrilldown/'+geo_type+'/'+geo_id+'/acuity'
  )

  useEffect(() => {
    if (acData !== null && acData !== undefined) {
      if (acData.response_code === 200) {
        setAcuityData(acData.response_data)
      } else {
        setErrorCode(acData.response_code)
        setErrorMessage(acData.response_message)
        setErrorSubject(acData.response_subject)
        seterrorOccured(true)
      }
    }
  }, [acData])

  const { data: drillData, error: drillDataError } = useGeoFetch(
    '',
    '',
    'vulnerability',
    'getdrilldownjson/'+geo_type+'/'+geo_id
  )

  useEffect(() => {
    if (drillData !== null && drillData !== undefined) {
      if (drillData.response_code === 200) {
        setDrillDownData(drillData.response_data)
      } else {
        setErrorCode(drillData.response_code)
        setErrorMessage(drillData.response_message)
        setErrorSubject(drillData.response_subject)
        seterrorOccured(true)
      }
    }
  }, [drillData])
  if ( errorOccured ||drillDataError ||acDataError) {
    return (
      <ErrorMessage
        errorCode={errorCode}
        errorMessage={''}
        errorSubject={errorSubject}
        errorDetails={errorMessage+' for Drill Down Graphs'}
      />
    )
  }
   
return  (
    <div className={styles.base}>
    
      <div className={styles.maindivFormat}>
       
          <span className={styles.captionFormat}>
          Acuity
            <p className={styles.subCaptionFormat}>
            Acuity of encounters associated with viral respiratory conditions during the last month.
            </p>
          </span>
       
          <SelectList
            dropdownData={drilldownFilterOptions[1]['options']}
            defaultValue={'2'}
            defaultLabel={drilldownFilterOptions[1]['options'][0].label}
            type={"Acuity"}
            selectType={'drilldown'}
            setDrillDownSelectedValueMethod={setDrillDownSelectedValueMethod}
          />
       
        {getAcuityData !== undefined 
        && locationName!==undefined
        && getDrillDownData!==undefined 
        && drillDownSelectedValue!=='3'? (
            <>
        <DrillDownChart data={getAcuityData} 
         type={"Acuity"}
        locationName={locationName}
         dataSeries={JSON.parse(getDrillDownData[2].json_value)}
         displayDate={displayDate}
         drillDownState={drillDownSelectedValue}
         />
         
        </>
          ) : ''}
           {getAcuityData !== undefined 
        && locationName!==undefined
        && getDrillDownData!==undefined 
        && drillDownSelectedValue==='3'? (
            <>
        <DrillDownChart data={getAcuityData} 
         type={"Acuity"}
        locationName={locationName}
         dataSeries={JSON.parse(getDrillDownData[3].json_value)}
         displayDate={displayDate}
         drillDownState={drillDownSelectedValue}/>
         
        </>
          ) : ''}
          
          
       
      </div>
     
    
    </div>
    
  )
}

export default AcuityGraph
