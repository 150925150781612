import React, { useEffect } from 'react'
import { useGeoState, useGeoFetch } from '../../components/GeoProvider'
import { useAuthState } from '../../components/AuthProvider'
import dayjs from 'dayjs'
import ErrorLogs from '../../components/ErrorBoundary/errorlogs'
const MSAApiGeoId = ({ setMapData, setStatsData, setActiveLocationData }) => {
  const { dataStartDate, dataEndDate, setDisplayDate, getGeoIdandGeoType } =
    useGeoState()
  var geo_parent = getGeoIdandGeoType.geoid  
  const { user } = useAuthState()
  const lastweekEndDate = dayjs().day(-1).format('YYYY-MM-DD')
debugger
  const { data: msaData, error: MapmsaError } = useGeoFetch(
    dataStartDate,
    dataEndDate,
    'fluMap',
    'MSA/?geo_parent=' + geo_parent
  )

  useEffect(() => {
    if (msaData !== null && msaData !== undefined) {
      if (msaData.response_code === 200) {       
        setMapData(msaData.response_data)
      } else if (msaData.response_code === 404) {
        setMapData(msaData.response_data)
        ErrorLogs({
          message_type: 'Error',
          error_details: `${msaData.response_subject}, ${msaData.response_message},for ${geo_parent}`,
          user: user,
        })
      } else {
        // setErrorCode(msaData.response_code)
        // setErrorMessage(msaData.response_message)
        // setErrorSubject(msaData.response_subject)
        // seterrorOccured(true)
      }
    }
  }, [msaData])

  // Active Metro Areas
  const { data: activeCbsasData, error: activeCbsasDataError } = useGeoFetch(
    dataStartDate,
    dataEndDate,
    'fluLocations',
    'activelocations?geo_type=MSA&geo_parent_code=' + geo_parent
  )

  useEffect(() => {
    if (activeCbsasData !== null && activeCbsasData !== undefined) {
      setActiveLocationData(activeCbsasData)
    }
  }, [activeCbsasData])

  const { data: stats, error: stateDataError } = useGeoFetch(
    lastweekEndDate,
    lastweekEndDate,
    'fluStats',
    geo_parent + '?geo_type=MSA'
  )

  useEffect(() => {
    if (stats !== null && stats !== undefined) {
      if (stats.response_code === 200) {
        setStatsData(stats.response_data)
      } else if (stats.response_code === 404) {
        setStatsData(stats.response_data)
        ErrorLogs({
          message_type: 'Error',
          error_details: `${stats.response_subject}, ${stats.response_message},for ${geo_parent}`,
          user: user,
        })
      } else {
        // setErrorCode(stats.response_code)
        // setErrorMessage(stats.response_message)
        // setErrorSubject(stats.response_subject)
        // seterrorOccured(true)
      }
    }
  }, [stats])

  return <></>
}

export default MSAApiGeoId
