import React, { useEffect } from 'react'
import { useGeoState, useGeoFetch } from '../../components/GeoProvider'
import { useAuthState } from '../../components/AuthProvider'
import dayjs from 'dayjs'
import ErrorLogs from '../../components/ErrorBoundary/errorlogs'
const Zip3Api = ({  setMapData, setStatsData, setActiveLocationData }) => {    
  const {
    dataStartDate,
    dataEndDate,
    setDisplayDate
  } = useGeoState()  
  var geo_parent = 'US'
  const { user } = useAuthState()
  const lastweekEndDate = dayjs().day(-1).format('YYYY-MM-DD')

  const { data: zip3Stats, error: zip3DataError } = useGeoFetch(
    lastweekEndDate,
    lastweekEndDate,
    'fluStats',
    'ZIP3/'
  )

  useEffect(() => {
    if (zip3Stats !== null && zip3Stats !== undefined) {
      if (zip3Stats.response_code === 200) {
        setStatsData(zip3Stats.response_data)
      } else if (zip3Stats.response_code === 404) {
        setStatsData(zip3Stats.response_data)
        ErrorLogs({
          message_type: 'Error',
          error_details: `${zip3Stats.response_subject}, ${zip3Stats.response_message},for ${geo_parent}`,
          user: user,
        })
      } else {
        // setErrorCode(zip3Stats.response_code)
        // setErrorMessage(zip3Stats.response_message)
        // setErrorSubject(zip3Stats.response_subject)
        // seterrorOccured(true)
      }
    }
  }, [zip3Stats])

  ////zip3 
  const { data: zip3Data, error: MapZipError } = useGeoFetch(
    lastweekEndDate,
    lastweekEndDate,
    'fluMap',
    'ZIP3/?geo_parent=US'
  )

  useEffect(() => {
    if (zip3Data !== null && zip3Data !== undefined) {
      if (zip3Data.response_code === 200) {        
        setMapData(zip3Data.response_data)
      } else {
        // setErrorCode(zip3Data.response_code)
        // setErrorMessage(zip3Data.response_message)
        // setErrorSubject(zip3Data.response_subject)
        // seterrorOccured(true)
      }
    }
  }, [zip3Data])


  const { data: activeZIP3Data, error: activeZIP3DataError } = useGeoFetch(
    dataStartDate,
    dataEndDate,
    'fluLocations',
    'activelocations?geo_type=ZIP3'
  )

  useEffect(() => {
    if (activeZIP3Data !== null && activeZIP3Data !== undefined) {
      setActiveLocationData(activeZIP3Data)
    }
  }, [activeZIP3Data])

  return (
    <>
    
    </>
  )
}

export default Zip3Api