import React, { useState, useEffect } from 'react'
import styles from './GeoHeader.module.scss'
import getVectorImage from '../../../utils/getVectorImage'
import { useGeoFetch, useGeoState } from '../../../components/GeoProvider'
import dayjs from 'dayjs'
import typeDisplayOptions from '../../Dashboard/typeDisplayOptions'
import SelectList from '../../../components/SelectMenuList'
import DefaultBreadcrumbs from '../../../components/Breadcrumbs/DefaultBreadcrumbs'
import Search from '../../../components/Search'

const HeaderDisplay = ({ headerType, geo_id }) => {
  const lastweekEndDate = dayjs().day(-1).format('YYYY-MM-DD')
  const [errorSubject, setErrorSubject] = useState()
  const [errorOccured, seterrorOccured] = useState(false)
  const [getgeoid, setgeoid] = useState('')
  const [getistrue, setistrue] = useState('')
  const [getDatavalue, setDatavalue] = useState('')
  const [getPercentage, setPercentage] = useState('')
  const [getstatename, setStatename] = useState('')
  const { setLocationName } = useGeoState()
  const { data: headerdata, error: stateDataError } = useGeoFetch(
    lastweekEndDate,
    lastweekEndDate,
    'vulnerability',
    'getheader/'+geo_id
  )

  useEffect(() => {
    if (headerdata !== null && headerdata !== undefined) {
      if (headerdata.response_code === 200) {
        setgeoid(headerdata.response_data.geo_id)
        setDatavalue(headerdata.response_data.vulnerability_index)
        setistrue(headerdata.response_data.change_percentage>0?true:false)
        setPercentage(headerdata.response_data.change_percentage)
        setStatename(headerdata.response_data.geo_name)
        headerType === 'MSA' &&
          setLocationName(headerdata.response_data.geo_name)
      } else {
        setErrorSubject(headerdata.response_subject)
        seterrorOccured(true)
      }
    }
  }, [headerdata])

  if (stateDataError || (errorOccured && errorSubject !== undefined)) {
    return <div>Unable to get the data for header {errorSubject}</div>
  }

  return (
    <>
      <div
        className={
          headerType === 'NAT'
            ? styles.natDiv
            : headerType === 'STATE'
            ? styles.stateDiv
            : styles.geoDiv
        }
      >
        <span className={styles.firstSpan}>
          {headerType !== 'STATE' && headerType !== 'NAT'
            ? getstatename
            : getgeoid}
        </span>
        <span className={styles.secondSpan}>{getVectorImage(getistrue)}</span>
        <span
          className={getistrue ? styles.stdatavalueup : styles.datavaluedown}
        >
          {' '}
          {getPercentage}%{' '}
        </span>
        <span className={styles.forthSpan}>
          {' '}
          ({getDatavalue})
          {headerType !== 'NAT' && headerType !== 'STATE' ? (
            <span className={styles.sinceWeek}> since last month</span>
          ) : (
            ''
          )}
        </span>
      </div>
    </>
    // <>
    //   <div
    //     className={
    //       headerType === 'NAT'
    //         ? styles.natDiv
    //         : headerType === 'STATE'
    //         ? styles.stateDiv
    //         : styles.geoDiv
    //     }
    //   >
    //     <span className={styles.firstSpan}>
    //       {headerType !== 'STATE' && headerType !== 'NAT'
    //         ? getstatename
    //         : getgeoid}
    //     </span>
    //     <span className={styles.forthSpan}>
    //       {' '}
    //       ({getDatavalue})
    //     </span>
    //   </div>
    // </>
  )
}

const Header = ({ headerType, headerNumber, geo_id }) => {
  const [searchCriteria, setSearchCriteria] = React.useState([])
  const [activeCbsa, setActiveCbsa] = useState()
  const { locationName } = useGeoState()
  const { data: activeCbsasData, error: activeCbsasDataError } = useGeoFetch(
    '',
    '',
    'vulnerability',
    'searchlocations/vulnerability?geo_type=MSA'
  )

  useEffect(() => {
    if (activeCbsasData !== null && activeCbsasData !== undefined) {
      if (activeCbsasData.response_code === 200) {
        setActiveCbsa(activeCbsasData.response_data)
      
       }
    }
  }, [activeCbsasData])
  return (
    <>
      <div className={styles.pageHeader}>
        <div className={styles.pageHeaderText}>
          <DefaultBreadcrumbs
            geo_parent={headerType}
            geo_id={geo_id}
            geo_type={'MSA'}
            dashboardType={'VULNERABILITY'}
          />
          <SelectList
            dropdownData={typeDisplayOptions}
            defaultValue={'vulnerability'}
            defaultLabel={'Vulnerability'}
          />
        </div>
        <div className={styles.geoName}>{locationName}</div>
        <div className={styles.pageHeaderRight}>
          <div className={styles.pageHeaderTextValue}>
            <HeaderDisplay headerType={'NAT'} geo_id={'NAT'}></HeaderDisplay>
            <HeaderDisplay
              headerType={'STATE'}
              geo_id={headerType}
            ></HeaderDisplay>
            <HeaderDisplay headerType={'MSA'} geo_id={geo_id}></HeaderDisplay>
          </div>
          <div className={styles.pageHeaderSearch}>
            {activeCbsa !== undefined && (
              <Search
                criteria={searchCriteria}
                onChange={setSearchCriteria}
                entity={'vulnerability'}
                showMSAs={true}
                activeMetros={activeCbsa}
                placeholder={'Search State or MSA'}
              />
            )}
          </div>
        </div>
      </div>
    </>
  )
}
export default Header
