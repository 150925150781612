import { React, useState, useEffect } from 'react'
import styles from './RaceEthnicity.module.scss'
import { useGeoFetch,useGeoState } from '../../../components/GeoProvider'
import ErrorMessage from '../../../components/Layout/ErrorMessage'
import ColumnChart from '../../../components/ColumnChartVul'
import ApiRaceEthnicity from './ApiRaceEthnicity'
const RaceEthnicity = ({ geo_type, geo_id,searchLocation,searchLocationText }) => {
  const {locationName,displayDate} =useGeoState()
  const comparisonStat=((searchLocation===''||searchLocation===undefined)?false:true)  
  const [genderAge, setGenderAge] = useState()
  const [genderAge1, setGenderAge1] = useState()
  const [errorCode, setErrorCode] = useState()
  const [errorMessage, setErrorMessage] = useState()
  const [errorSubject, setErrorSubject] = useState()
  const [errorOccured, seterrorOccured] = useState(false)
  

    const { data: genderAgeData, error: genderAgeDataError } = useGeoFetch(
      '',
      '',
      "vulnerability",
      "getraceethnicity/"+geo_type + '/' + geo_id
    )
  
    useEffect(() => {
      if (genderAgeData !== null && genderAgeData !== undefined) {
        if (genderAgeData.response_code === 200) {         
          setGenderAge(genderAgeData.response_data)
  
        } else {
          setErrorCode(genderAgeData.response_code)
          setErrorMessage(genderAgeData.response_message)
          setErrorSubject(genderAgeData.response_subject)
          seterrorOccured(true)
        }
      }
    }, [genderAgeData])

    if (errorOccured) {
      return (
        <ErrorMessage
          errorCode={errorCode}
          errorMessage={errorMessage}
          errorSubject={errorSubject}
          errorDetails={errorMessage+" for Race & Ethnicity."}
        />
      )
    }
    const setAgeDataSecond = (data) => {
      setGenderAge1(data);
    };
    
    return  (
    <div className={styles.base}>
      <div className={styles.maindivFormat}>
        
          <div className={styles.captionFormat}>
            Race & Ethnicity
            <div className={styles.subCaptionFormat}>
            <span>Race and ethnicity of the overall population in the selected region.</span>
            <div style={{marginTop:'10px'}}>
              <a href='https://www.census.gov/programs-surveys/acs/guidance/estimates.html' target='_blank'>U.S. Census Bureau </a> (Jan 1, 2023-Dec 31,2023)
            </div>
            </div>
          </div>
        
        {genderAge !== undefined && locationName!==undefined && searchLocationText!==undefined ? (
            <>
               {comparisonStat===true?
               <>
                <ApiRaceEthnicity geo_type={geo_type} geo_id={searchLocation} setGenderAge1={setAgeDataSecond}/>
                <ColumnChart data={genderAge1!==undefined&&[genderAge,genderAge1]} 
               chartName="raceEthnicity" 
               renderType="dashboard" 
               multiColumn={comparisonStat} 
               locationName={locationName}
               searchLocationText={searchLocationText}
               displayDate={displayDate}
                />
                </>: <ColumnChart data={[genderAge]} 
               chartName="raceEthnicity" 
               renderType="dashboard" 
               multiColumn={comparisonStat} 
               locationName={locationName}
               searchLocationText={searchLocationText}
               displayDate={displayDate}
                />
                
              }
               
            </>
          ) : null}
      </div>
     
    </div>
    
  ) 
}

export default RaceEthnicity
