import { React, useState, useEffect } from 'react'
import styles from './RespiratoryGraph.module.scss'
import { useGeoFetch,useGeoState } from '../../../components/GeoProvider'
import DrillDownChart from '../../../components/DrillDownChart'
import drilldownFilterOptions from './drilldownFilterOptions'
import SelectList from '../../../components/SelectList'
import ErrorMessage from '../../../components/Layout/ErrorMessage'
import Spinner from '../../../components/Loader/Spinner'
const RespiratoryGraph = ({ geo_type, geo_id }) => {
  
  const [errorCode, setErrorCode] = useState()
  const [errorMessage, setErrorMessage] = useState()
  const [errorSubject, setErrorSubject] = useState()
  const [errorOccured, seterrorOccured] = useState(false)
  const [getDiseaseData, setDiseaseData] = useState()
  const [getDrillDownData, setDrillDownData] = useState()
  const [drillDownSelectedValue,setDrillDownSelectedValue]=useState('')
  const setDrillDownSelectedValueMethod=(data)=>{setDrillDownSelectedValue(data)}
  const { locationName,displayDate,getRespiratoryState } = useGeoState()
  const { data: disData, error: disDataError } = useGeoFetch(
    '',
    '',
    'vulnerability',
    'getdrilldown/'+geo_type+'/'+geo_id+'/respiratory'
  )

  useEffect(() => {
    if (disData !== null && disData !== undefined) {
      if (disData.response_code === 200) {
        setDiseaseData(disData.response_data)
      } else {
        setErrorCode(disData.response_code)
        setErrorMessage(disData.response_message)
        setErrorSubject(disData.response_subject)
        seterrorOccured(true)
      }
    }
  }, [disData])

  const { data: drillData, error: drillDataError } = useGeoFetch(
    '',
    '',
    'vulnerability',
    'getdrilldownjson/'+geo_type+'/'+geo_id
  )

  useEffect(() => {
    if (drillData !== null && drillData !== undefined) {
      if (drillData.response_code === 200) {
        setDrillDownData(drillData.response_data)
      } else {
        setErrorCode(drillData.response_code)
        setErrorMessage(drillData.response_message)
        setErrorSubject(drillData.response_subject)
        seterrorOccured(true)
      }
    }
  }, [drillData])
  if ( errorOccured ||drillDataError ||disDataError ) {
    return (
      <ErrorMessage
        errorCode={errorCode}
        errorMessage={''}
        errorSubject={errorSubject}
        errorDetails={errorMessage+' for Drill Down Graphs'}
      />
    )
  }
return  (
    <div className={styles.base}>
      <div className={styles.maindivFormat}>
        
          <span className={styles.captionFormat}>
          Respiratory Conditions
          
            <p className={styles.subCaptionFormat}>
            Individuals with viral respiratory conditions during the last month.
            </p>
          </span>      
<div>
          <SelectList
            dropdownData={drilldownFilterOptions[0]['options']}
            defaultValue={'0'}
            defaultLabel={drilldownFilterOptions[0]['options'][0].label}
            selectType={'drilldown'}
            type={"Respiratory Condition"}
            setDrillDownSelectedValueMethod={setDrillDownSelectedValueMethod}
          />
          </div>
        {getDiseaseData !== undefined 
        && locationName!==undefined 
        && getDrillDownData!==undefined 
        &&drillDownSelectedValue!=='1'? (
            <>
        <DrillDownChart data={getDiseaseData} 
        type={"Respiratory Condition"}
        locationName={locationName} 
        dataSeries={JSON.parse(getDrillDownData[0].json_value)}
       displayDate={displayDate}
       drillDownState={drillDownSelectedValue}
        />
        </>
          ) : ''}
          {getDiseaseData !== undefined 
        && locationName!==undefined 
        && getDrillDownData!==undefined 
        &&drillDownSelectedValue==='1'? (
            <>
        <DrillDownChart data={getDiseaseData} 
        type={"Respiratory Condition"}
        locationName={locationName} 
        dataSeries={JSON.parse(getDrillDownData[1].json_value)}
       displayDate={displayDate}
       drillDownState={drillDownSelectedValue}
        />
        </>
          ) :''}
          
       
      </div>
      
    
    </div>
    
  )
}

export default RespiratoryGraph
