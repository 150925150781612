import React, { useEffect } from 'react'
import {  useGeoFetch } from '../../../components/GeoProvider'
const ApiRaceEthnicity = ({  geo_type, geo_id,setGenderAge1 }) => {      
  const { data: genderAgeData, error: genderAgeDataError } = useGeoFetch(
    '',
    '',
    "vulnerability",
    "getraceethnicity/"+geo_type + '/' + geo_id
  )

  useEffect(() => {
    if (genderAgeData !== null && genderAgeData !== undefined) {
      if (genderAgeData.response_code === 200) {        
        
        setGenderAge1(genderAgeData.response_data)

      } else {
        // setErrorCode(genderAgeData.response_code)
        // setErrorMessage(genderAgeData.response_message)
        // setErrorSubject(genderAgeData.response_subject)
        // seterrorOccured(true)
      }
    }
  }, [genderAgeData])


  return (
    <>
    
    </>
  )
}

export default ApiRaceEthnicity


