import getThresholdValue from '../../utils/getThresholdValueVulnerability'
import moment from 'moment'
function toolTipFormatter() {
  let istrue=this.point.change_percentage>0?true:false
  let imageName = (istrue)? "Vector-up-red.png":"Vector-down-green.png"
  var url =  `${window.location.origin}/${imageName}`; 
  let stokeColor = getThresholdValue(this.point.threshold_level).OutlineColor
  let fillColor = getThresholdValue(this.point.threshold_level).color
  let color = '#007000'
  if (istrue) color = '#C40000'
  const divData =
    //return
    `<div style="width:100%;padding-top:16px;padding-left: 16px;padding-right: 16px;height:160px;background-color:white;font-family:Optum Sans;border: 1px solid #CBCCCD;
    border-radius: 4px;">
    <div style="width:90%; padding-top:16px;padding-right:5px;display:inline;
    color: #002677;font-family:'Optum Sans';font-style: normal;font-weight: 700;
    font-size: 14px;line-height: 18px;
    ">${this.point.geo_name} </div>
    <br/>
    <span style="font-style: normal; padding-top:8px;font-weight: 700;
    font-size:14px; line-height: 18px; display: flex; align-items: center;
    color: #4B4D4F;">
    ${moment(this.point.week_ending).format('MMM')}  ${moment(this.point.week_ending).startOf('month').format('D')} -  ${moment(this.point.week_ending).endOf('month').format('D')}, ${moment(this.point.week_ending).format('YYYY')}</span>
    <div style="padding-top:8px;">
    <div style="border-radius:0%;background-color:${fillColor}; 
    display: inline-block; width:16px; height:16px;border:1px solid ${stokeColor}"></div>&nbsp;
    <div style='display: inline-block; font-size:14px;font-weight:400;vertical-align:super;'>${this.point.threshold}</div>
    </div>
    <div>
    <span style="font-style: normal; padding-top:8px;font-weight: 400;
    font-size:14px; line-height: 18px; display: flex; align-items: center;
    color: #4B4D4F;">Incidence Rate :<span style="font-family: 'Optum Sans', sans-serif;
    font-style:normal;font-weight: 700;font-size: 14px;
    line-height:18px;display: flex;align-items:center;
    color: #4B4D4F;padding:2px;
    ">${this.point.vulnerability_index}</span></span>                
     </div>  
     <div style="
     width: 157px;
     padding-top: 8px;
     padding-bottom: 16px;
     padding-right:16px;
     font-family: 'Optum Sans', sans-serif;
     font-style: normal;
     font-weight: 400;
     font-size: 14px;
     line-height: 18px;
     display: flex;
     align-items: center;
     color: #4B4D4F;
     ">
 
    
    <div  style="font-weight:bold; color:${color};padding:2px; display:flex;">    
    
    <div style="height:18px; width:16px; margin-top:0px">
    <img src="${url}"/>
    </div>
    <div style="margin-left:4px;margin-right:4px"> ${
      this.point.change_percentage
    }% 
    </div>
    </div>     from previous month
    </div>  
    </div>`
  return divData
}

export default toolTipFormatter
