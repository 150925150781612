import { React, useState, useEffect } from 'react'
import styles from './GenderAge.module.scss'
import ColumnChart from '../../components/ColumnChart'
import GenderAge from '../../components/ColumnChart/GenderAgeDetails'
import { useGeoFetch } from '../../components/GeoProvider'
import ErrorMessage from '../../components/Layout/ErrorMessage'
const GenderAgePanel = ({ geo_type, geo_id }) => {  
  const [genderAge, setGenderAge] = useState()
  const [errorCode, setErrorCode] = useState()
  const [errorMessage, setErrorMessage] = useState()
  const [errorSubject, setErrorSubject] = useState()
  const [errorOccured, seterrorOccured] = useState(false)

  const { data: genderAgeData, error: genderAgeDataError } = useGeoFetch(
    '',
    '',
    'genderagedetails',
    geo_type + '/' + geo_id + '?dataType=CDC'
  )

  useEffect(() => {
    if (genderAgeData !== null && genderAgeData !== undefined) {
      if (genderAgeData.response_code === 200) {        
        setGenderAge(genderAgeData.response_data)
      } else {
        setErrorCode(genderAgeData.response_code)
        setErrorMessage(genderAgeData.response_message)
        setErrorSubject(genderAgeData.response_subject)
        seterrorOccured(true)
      }
    }
  }, [genderAgeData])

  if (genderAgeDataError || errorOccured) {
    return (
      <ErrorMessage
        errorCode={errorCode}
        errorMessage={''}
        errorSubject={errorSubject}
        errorDetails={errorMessage}
      />
    )
  }

  return genderAge ? (
    <div className={styles.base}>
      <div className={styles.maindivFormat}>
        <div>
          <span className={styles.captionFormat}>
             Age & Gender
            <p className={styles.subCaptionFormat}>
              <a href='https://www.census.gov/popclock/' target='_blank'>U.S. Census Bureau</a> (Apr 1,2023 - Mar 31,2024)
            </p>
          </span>
        </div>

        <div>
          {genderAge !== undefined ? (
            <>
              <ColumnChart data={genderAge} chartName="genderAgechart"  renderType="home" />
              <GenderAge data={genderAge}></GenderAge>
            </>
          ) : null}
        </div>
      </div>
    </div>
  ) : null
}

export default GenderAgePanel
