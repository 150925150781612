import React from 'react'
const OneHealthcareIdLogin = () => {
 
  React.useEffect(() => {
    if (
      process.env.REACT_APP_DEVELOPMENT_MODE === undefined ||
      process.env.REACT_APP_DEVELOPMENT_MODE === 'true'
    ) {
      window.location.replace('/oauth2/login-redirect')
    } else {
      fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/login/url?type=${window.location.origin}`)
        .then((response) => response.text())
        .then((redirectUrl) => window.location.replace(redirectUrl))
    }
  }, [])

  return <div>Redirecting to login...</div>
}

export default OneHealthcareIdLogin
