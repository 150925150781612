import jwtDecode from 'jwt-decode'
import fetchAdUser from './fetchAdUser'
// import fetchUser from './fetchUser'

import { USER_LOGIN_REDIRECT, USER_AUTH_KEY } from '../../utils/constants'

export const SET_CREATE_SESSION_FLAG = 'SET_CREATE_SESSION_FLAG'
export const SET_ERROR = 'SET_ERROR'
export const SET_REDIRECT_ROUTE = 'SET_REDIRECT_ROUTE'
export const SET_USER = 'SET_USER'
export const SET_AUTH_MODE = 'SET_AUTH_MODE'

export const createSession = (id_token, client_info, session_state, state) => {
  return async (dispatch, getState) => {
    const { isCreatingSession } = getState()
    const ttlMinutes = 2 * 60 // two hours
    if (isCreatingSession) return

    dispatch({ type: SET_CREATE_SESSION_FLAG, isCreatingSession: true })
    try {
      if (id_token) {
        dispatch({ type: SET_AUTH_MODE, authMode: 'msid' })
        // If id_token is present it is a AD authentication. Validate the token at the backend and
        // get back the user information along with the session cookies.

        const user = await fetchAdUser(
          id_token,
          client_info,
          session_state,
          state
        )
        dispatch({ type: SET_USER, user })
      } else {
        dispatch({ type: SET_AUTH_MODE, authMode: 'ohid' })
        if (
          process.env.REACT_APP_DEVELOPMENT_MODE === undefined ||
          process.env.REACT_APP_DEVELOPMENT_MODE === 'true'
        ) {
          // TODO: add this in when backend is ready! This is OAuth 2.0 flow to
          // check the existence of user in the authenticating system.
          // const user = await fetchUser(TEST_USER_EMAIL)
          const user = {
            firstName: 'Vijay',
            lastName: 'Yadav',
            email: 'vijay_yadav29@optum.com',
            username: 'vyadav29',
            sortName: 'VY',
            phone: '+18888888888',
            role: 'Basic',
            preferences: {
              timeZone: 'America/Chicago',
              timeZoneDisplay: 'DD/MM/YYYY, h:mm:ss A z',
            },
            expiry: new Date().getTime() + ttlMinutes * 60 * 1000,
            uuid: 'fc8c9855-711b-40b1-9548-225562149f45',
            userid: 3,
          }
          window.localStorage.setItem('requester', JSON.stringify(user))
          window.newrelic.setCustomAttribute('username', user.username)
          dispatch({ type: SET_USER, user })
        } else {
          const authenticate = async () => {
            //  check for cookie 'idpprofile' that should be present
            // this cookie is only valid for 30seconds
            const cookies = document.cookie
            const cookie = require('cookie')
            const cookieObjects = cookie.parse(cookies)
            const idpCookie = cookieObjects['idprsvprofile']
            if (!idpCookie) {
              return
            }
            const {
              username,
              givenName,
              familyName,
              profileName,
              role,
              email,
              phone,
              exp,
              uuid,
              id,
            } = jwtDecode(idpCookie)
            // TODO: enable this when we are going to have backend store user information
            // const user = await fetchUser(username)
            window.localStorage.setItem('exp', exp)
            const user = {
              username: username,
              firstName: givenName,
              lastName: familyName,
              sortName: profileName,
              role: role,
              email: email,
              phone: phone,
              preferences: {
                timeZone: 'America/Chicago',
                timeZoneDisplay: 'DD/MM/YYYY, h:mm:ss A z',
              },
              expiry: new Date().getTime() + ttlMinutes * 60 * 1000,
              uuid: uuid,
              userid: id,
            }
            window.localStorage.setItem('requester', JSON.stringify(user))
            window.newrelic.setCustomAttribute('username', user.username)
            dispatch({ type: SET_USER, user })           
          }

          await authenticate()
        }
      }
      dispatch({ type: SET_REDIRECT_ROUTE, route: '/' })
      dispatch({ type: SET_CREATE_SESSION_FLAG, isCreatingSession: false })
    } catch (error) {
      dispatch({ type: SET_ERROR, error })
      dispatch({ type: SET_CREATE_SESSION_FLAG, isCreatingSession: false })
    }
  }
}

export const clearSession = () => {
  return async (dispatch) => {
    window.localStorage.removeItem(USER_AUTH_KEY)
    window.localStorage.removeItem(USER_LOGIN_REDIRECT)
    dispatch({ type: SET_USER, user: null })
  }
}

export const preventNextRedirect = () => ({
  type: SET_REDIRECT_ROUTE,
  route: null,
})
