import React, { useEffect } from 'react'
import styles from './About.module.scss'

const DataSources = () => {
  // const history = useHistory()

  return (
    <div className={styles.containerDiv}>
      <div className={styles.firstContainer}>
      <div className={styles.bottomPadded2}>
          
      <h4>Machine learning - Ensemble methods</h4>
          <p>
          Ensemble learning is the machine learning approach used to forecast Flu and COVID-19 activity. The ensemble method combines multiple learning models into one optimal predictive model. This technique facilitates greater prediction stability by minimizing sources of error, including variance, noise, and bias, from the base models. The blended output will produce more accurate predictions for improved decision-making.
          </p>

          <h4>Incidence Proportion</h4>
          <p>Surveillance and forecasting of disease incidence in the weekly time interval for any given region, is defined as proportion: (# of new disease cases / total # of members receiving care) x 100</p>
          
          <h4>Epiweek</h4>
          <p>An epidemiological week, commonly referred to as an epi week or a CDC week, is simply a standardized method of counting weeks to allow for the comparison of data year after year. The first epi week of the year ends, by definition, on the first Saturday of January, if it falls at least four days into the month. Each epi week begins on a Sunday and ends on a Saturday.</p>
       
        <h4>Horizons</h4>
       <p>These are the forecasting horizons. As the forecast generates 4 weeks/steps ahead, all epiweeks (except the initial and latest epiweeks) have been forecasted 4 times. Horizon ‘N’ (N being 1, 2…4) indicates ‘N’ step ahead forecast.</p>

       <h4>Data Source</h4>
       <p>A centralized repository that incorporates data from a large national payer, including all primary claim platforms. It provides up to ten years of claims, provider, financial management, product, plan, & benefit, customer, consumer/ member, and clinical data.</p>
        
          <h4>Influenza</h4>
          <p>Cases are identified from adjudicated medical claims, based on positive laboratory test results and specific ICD-10 diagnosis codes associated with influenza:</p>
          <ul>
            <li><p>J069</p></li>
            <li><p>J09-J11</p></li>
            <li><p>J1289</p></li>
            <li><p>J1290</p></li>
            <li><p>J18</p></li>
            <li><p>J209</p></li>
            <li><p>J40</p></li>
            
          </ul>

          <h4>COVID-19</h4>
          <p>Cases are identified from adjudicated medical claims, based on positive laboratory test results and specific ICD-10 diagnosis codes associated with COVID-19:</p>
          <ul>
            <li><p>U071</p></li>          
          </ul>
        

        </div>
      </div>
      <div className={styles.secondContainer}>
      <div className={styles.bottomPadded2}>
      
      <h4>RSV</h4>
          <p>Cases are identified from adjudicated medical claims, based on positive laboratory test results, procedures, and specific ICD-10 diagnosis codes associated with RSV:</p>
          <ul>
            <li><p>B924</p></li>
            <li><p>J121</p></li>
            <li><p>J205</p></li>
            <li><p>J210</p></li>
          </ul>

         

       <h4>Respiratory Virus Activity</h4>
<p>Cases are identified from adjudicated medical claims, based on specific ICD-10 diagnosis codes associated with influenza, RSV, COVID-19, along with the additional viruses:</p>

<h4>Adenovirus</h4>
<ul>
  <li>B340</li>
  <li>B970</li>
  <li>J120</li>
</ul>
<h4>Parainfluenza Virus (PIV)</h4>
<ul>
  <li>J122</li>
  <li>J204</li>
</ul>

<h4>Human Metapneumovirus (HMPV)</h4>
<ul>
  <li>B9781</li>
  <li>J123</li>
  <li>J211</li>
</ul>


<h4>Rhinovirus</h4>
<ul>
  <li>J206</li>
</ul>

<h4>Human Coronavirus (HCoV)</h4>
<ul>
  <li>B342</li>
  <li>B9721</li>
  <li>B9729</li>
  <li>J1281</li>
</ul>
        </div>
        
      </div>
    </div>
  )
}

export default DataSources
