import React, { useState, useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { useGeoState, useGeoFetch } from '../../components/GeoProvider'
import ErrorMessage from '../../components/Layout/ErrorMessage'
import DisplayDateFluNew from '../../components/DisplayDateFlu/DisplayDateFluNew'
import Spinner from '../../components/Loader/Spinner'
import EmptyMap from '../../components/Map/emptyMap'
import Timeline from '../../components/Timeline'
import MapLegend from './cdcMapLegendVertical'
import SideTable from './SideTable'
import styles from './MapPage.module.scss'
import dayjs from 'dayjs'
import ErrorLogs from '../../components/ErrorBoundary/errorlogs'
import { useAuthState } from '../../components/AuthProvider'
import MSAApi from './MSAApi'
import MSAApiGeoId from './MSAApiGeoId'

import Zip3Api from './Zip3Api'
import StateWithProviderMarker from './StateWithProviderMarker'
const DashboardMap = () => {
  
  const msaRef = useRef(null)
  const stateRef = useRef(null)
  const zip3Ref = useRef(null)

  const { user } = useAuthState()
  const slotTimeInSeconds = 604800000
  const latestPositionString = ''
  const slotString = 'week'
  const location = useLocation()
  const hash = location.hash
  const [getMapStates, setMapStates] = useState()
  const [getMapZip3, setMapZip3] = useState()
  const [activeCbsa, setActiveCbsa] = useState()
  const [activeZip3, setactiveZip3] = useState()
  const [metros, setMetros] = useState()
  const [getStats, setStats] = useState()
  const [getMSAStats, setMSAStats] = useState()
  const [getZIP3Stats, setZIP3Stats] = useState()
  const [errorCode, setErrorCode] = useState()
  const [errorMessage, setErrorMessage] = useState()
  const [errorSubject, setErrorSubject] = useState()
  const [errorOccured, seterrorOccured] = useState(false)
  const [getStateJson, setStateJson] = useState()

  const {
    dataStartDate,
    dataEndDate,
    regionSelected,
    setRegionSelected,
    displayDate,
    getGeoIdandGeoType,
  } = useGeoState()
  const lastweekEndDate = dayjs().day(-1).format('YYYY-MM-DD')
  var geo_parent = 'US'
  if (hash) {
    if (hash.split('-').length > 1) {
      setRegionSelected(hash.split('-')[0].replace('#', ''))
    }
  }
  const { data: statesData, error: MapError } = useGeoFetch(
    dataStartDate,
    dataEndDate,
    'fluMap',
    `STATE/?geo_parent=${geo_parent}`
  )

  useEffect(() => {
    if (statesData !== null && statesData !== undefined) {
      if (statesData.response_code === 200) {       
        setMapStates(statesData.response_data)
      } else {
        setErrorCode(statesData.response_code)
        setErrorMessage(statesData.response_message)
        setErrorSubject(statesData.response_subject)
        seterrorOccured(true)
      }
    }
  }, [statesData, user])

  const { data: statesData1, error: MapError1 } = useGeoFetch(
    dataStartDate,
    dataEndDate,
    'fluMap',
    'STATE/?geo_parent=US'
  )

  useEffect(() => {
    if (statesData1 !== null && statesData1 !== undefined) {
      if (statesData1.response_code === 200) {        
        setMapStates(statesData1.response_data)
      } else {
        setErrorCode(statesData1.response_code)
        setErrorMessage(statesData1.response_message)
        setErrorSubject(statesData1.response_subject)
        seterrorOccured(true)
      }
    }
  }, [statesData1, user])

  const { data: stats, error: stateDataError } = useGeoFetch(
    lastweekEndDate,
    lastweekEndDate,
    'fluStats',
    'US/'
  )

  useEffect(() => {
    if (stats !== null && stats !== undefined) {
      if (stats.response_code === 200) {
        setStats(stats.response_data)
      } else {
        setErrorCode(stats.response_code)
        setErrorMessage(stats.response_message)
        setErrorSubject(stats.response_subject)
        seterrorOccured(true)
      }
    }
  }, [stats])

  const { data: zip3Stats, error: zip3DataError } = useGeoFetch(
    lastweekEndDate,
    lastweekEndDate,
    'fluStats',
    'ZIP3/'
  )

  useEffect(() => {
    if (zip3Stats !== null && zip3Stats !== undefined) {
      if (zip3Stats.response_code === 200) {
        setZIP3Stats(zip3Stats.response_data)
      } else if (zip3Stats.response_code === 404) {
        setZIP3Stats(zip3Stats.response_data)
        ErrorLogs({
          message_type: 'Error',
          error_details: `${zip3Stats.response_subject}, ${zip3Stats.response_message},for ${geo_parent}`,
          user: user,
        })
      } else {
      }
    }
  }, [zip3Stats, user, geo_parent])

  const { data: zip3Datanew, error: MapZipError1 } = useGeoFetch(
    dataStartDate,
    dataEndDate,
    'fluMap',
    'ZIP3/?geo_parent=US'
  )

  useEffect(() => {
    if (zip3Datanew !== null && zip3Datanew !== undefined) {
      if (zip3Datanew.response_code === 200) {
        ErrorLogs({
          message_type: 'Activity',
          error_details: 'Dashboard access success',
          user: user,
        })
        setMapZip3(zip3Datanew.response_data)
      } else {
      }
    }
  }, [zip3Datanew, user])

  const { data: activeZIP3Data, error: activeZIP3DataError } = useGeoFetch(
    dataStartDate,
    dataEndDate,
    'fluLocations',
    'activelocations?geo_type=ZIP3'
  )

  useEffect(() => {
    if (activeZIP3Data !== null && activeZIP3Data !== undefined) {
      setactiveZip3(activeZIP3Data)
    }
  }, [activeZIP3Data])
  useEffect(() => {
    let url = `${window.location.origin}/usStatesAll.json`
    fetch(url)
      .then(function (res) {
        return res.json()
      })
      .then(function (data) {
        setStateJson(data)
      })
      .catch(function (err) {
        console.log(err, ' error file usStatesAll.json')
      })
  }, [1])

  if (
    stateDataError ||
    MapError ||
    errorOccured ||
    activeZIP3DataError ||
    MapZipError1 ||
    zip3DataError ||
    MapError1
  ) {
    return (
      <ErrorMessage
        errorCode={errorCode}
        errorMessage={errorMessage}
        errorSubject={errorSubject}
        errorDetails={errorMessage}
      />
    )
  }
  const zipMapData = (data) => {
    setMapZip3(data)
  }
  const zipStatsData = (data) => {
    setZIP3Stats(data)
  }
  const zipActiveLocationData = (data) => {
    setactiveZip3(data)
  }

  const msaMapData = (data) => {
    setMetros(data)
  }
  const msaStatsData = (data) => {
    setMSAStats(data)
  }
  const msaActiveLocationData = (data) => {
    setActiveCbsa(data)
  }

  if (regionSelected === 'state') {
  }
  return (
    <div className={`${styles.base}`}>
      <div className={`${styles.cbsasPanel}`}>
        <>
          {regionSelected === 'state' && (
            <>
             

             <MSAApi
                setMapData={msaMapData}
                setStatsData={msaStatsData}
                setActiveLocationData={msaActiveLocationData}
              />
          
          {getGeoIdandGeoType.geoid !=='NAT' && <MSAApiGeoId
                setMapData={msaMapData}
                setStatsData={msaStatsData}
                setActiveLocationData={msaActiveLocationData}
              />}
             
              {getStats !== undefined ? (
                <SideTable stateRef={stateRef} stateData={getStats} />
              ) : (
                <div style={{ border: '1.5px solid #CBCCCD', height: '84vh' }}>
                  <Spinner />
                </div>
              )}
            </>
          )}
          {regionSelected === 'metro' && (
            <>
              

              {getMSAStats !== undefined ? (
                <SideTable msaRef={msaRef} stateData={getMSAStats} />
              ) : (
                <div style={{ border: '1.5px solid #CBCCCD', height: '84vh' }}>
                  <Spinner />
                </div>
              )}
            </>
          )}
          {regionSelected === 'zip3' && (
            <>
              {displayDate === lastweekEndDate && getMapZip3 === undefined && (
                <Zip3Api
                  setMapData={zipMapData}
                  setStatsData={zipStatsData}
                  setActiveLocationData={zipActiveLocationData}
                />
              )}

              {getZIP3Stats !== undefined ? (
                <SideTable zip3Ref={zip3Ref} stateData={getZIP3Stats} />
              ) : (
                <div style={{ border: '1.5px solid #CBCCCD', height: '84vh' }}>
                  <Spinner />
                </div>
              )}
            </>
          )}
        </>
      </div>
      <div className={`${styles.map}`}>
        {regionSelected === 'state' && (
          <>
            {getMapStates !== undefined ? (
              <>
                <div className={`${styles.providerHeader}`}>
                  <DisplayDateFluNew
                    showWeek={true}
                    latestPositionString={latestPositionString}
                    slotString={slotString}
                    slotTimeInSeconds={slotTimeInSeconds}
                    diseaseType={'Flu'}
                  />
                  <Timeline
                    statesData={getMapStates}
                    latestPositionString={latestPositionString}
                    slotString={slotString}
                    slotTimeInSeconds={slotTimeInSeconds}
                    markCounts={5}
                    loadError={stateDataError}
                  />
                </div>
                <MapLegend stateRef={stateRef}  msaRef={msaRef}/>

                {getMapStates && metros && activeCbsa? (
                  <StateWithProviderMarker
                    lastweekEndDate={lastweekEndDate}
                    stateRef={stateRef}
                    statesData={getMapStates}
                    stateEnable={true}
                    msaEnable={true}
                    activeCbsa={activeCbsa}
                    cbsaData={metros}
                    providers={getStats}
                    msaid={''}
                  />
                ):(<Spinner/>)}
              </>
            ) : (
              <EmptyMap stateJson={getStateJson} />
            )}
          </>
        )}
        {regionSelected === 'metro' && (
          <>
            {metros !== undefined &&
            getMapStates !== undefined &&
            activeCbsa !== undefined ? (
              <>
                <div className={`${styles.providerHeader}`}>
                  <DisplayDateFluNew
                    showWeek={false}
                    
                    latestPositionString={latestPositionString}
                    slotString={slotString}
                    slotTimeInSeconds={slotTimeInSeconds}
                    diseaseType={'Flu'}
                  />
                  <Timeline
                    statesData={getMapStates}
                    latestPositionString={latestPositionString}
                    slotString={slotString}
                    slotTimeInSeconds={slotTimeInSeconds}
                    markCounts={5}
                    loadError={stateDataError}
                  />
                </div>
                <MapLegend />

                {metros && getMapStates && (
                  <StateWithProviderMarker
                    lastweekEndDate={lastweekEndDate}
                    msaRef={msaRef}
                    statesData={getMapStates}
                    cbsaData={metros}
                    stateEnable={true}
                    msaEnable={true}
                    activeCbsa={activeCbsa}
                    providers={getStats}
                    msaid={''}
                  />
                )}
              </>
            ) : (
              <EmptyMap stateJson={getStateJson} />
            )}
          </>
        )}
        {regionSelected === 'zip3' && (
          <>
            {getMapZip3 !== undefined && activeZip3 && getMapStates ? (
              <>
                <div className={`${styles.providerHeader}`}>
                  <DisplayDateFluNew
                    showWeek={true}
                    latestPositionString={latestPositionString}
                    slotString={slotString}
                    slotTimeInSeconds={slotTimeInSeconds}
                    diseaseType={'Flu'}
                  />
                  <Timeline
                    statesData={getMapZip3}
                    latestPositionString={latestPositionString}
                    slotString={slotString}
                    slotTimeInSeconds={slotTimeInSeconds}
                    markCounts={5}
                    loadError={stateDataError}
                  />
                </div>
                <MapLegend />

                {getZIP3Stats && activeZip3 && getMapZip3 && (
                  <StateWithProviderMarker
                    lastweekEndDate={lastweekEndDate}
                    zip3Ref={zip3Ref}
                    zip3Data={getMapZip3}
                    stateEnable={true}
                    activeZip3Data={activeZip3}
                    statesData={getMapStates}
                    zip3Enable={true}
                    activeCbsa={activeCbsa}
                    providers={getStats}
                    msaid={''}
                  />
                )}
              </>
            ) : (
              <EmptyMap stateJson={getStateJson} />
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default DashboardMap
