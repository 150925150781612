import React from 'react'
import { Dialog } from '@reach/dialog'
import styles from './Modal.module.scss'

const Modal = ({ open, handleClose, children }) => {
  return (
    <Dialog className={styles.base} isOpen={open} onDismiss={handleClose}>
      {children}
    </Dialog>
  )
}

export default Modal
