import { React, useState } from 'react'
import styles from './VulnerabilityBreakdown.module.scss'
import RespiratoryCondition from './../VulnerabilityGeoDashboard/RespiratoryCondition'
import GenderAgeView from './../VulnerabilityGeoDashboard/GenderAgeView'
import Estimates from './../VulnerabilityGeoDashboard/Estimates'
import RaceEthnicityGraph from './../VulnerabilityGeoDashboard/RaceEthnicity'
const VulnerabilityBreakdown = ({ geo_type, geo_id }) => {
  const [searchLocationText,setSearchLocationText]=useState('')
  const setSearchLocationTextMethod=(data)=>{setSearchLocationText(data)}
  const [searchLocation,setSearchLocation]=useState('')
  const setSearchLocationValue = (data) => {
    setSearchLocation(data);    
  }
  return  (
    <div className={styles.base}>
      <div className={styles.maindivFormat}>
        <div className={styles.headerDiv}> 
          <div className={styles.captionFormat}>
          Vulnerability Population Breakdown
          <p className={styles.subCaptionFormat}>
          Interact with individual columns to further segment the impacted population captured by respiratory condition, acuity or comorbidity in the selected region.
            </p>
          </div>
         
        </div>
        <RespiratoryCondition geo_type={geo_type} geo_id={geo_id} />
      <hr/>
      <GenderAgeView data_type="vulnerability" geo_type={geo_type} geo_id={geo_id} 
      setSearchLocationValue={setSearchLocationValue} 
      searchLocation={searchLocation} 
      setSearchLocationTextMethod={setSearchLocationTextMethod} 
      searchLocationText={searchLocationText}
      />
      <hr/>
      <RaceEthnicityGraph 
      geo_type={geo_type} 
      geo_id={geo_id} 
      searchLocation={searchLocation}  
      searchLocationText={searchLocationText}
      />   
      <hr/>
      <Estimates
       geo_type={geo_type} 
        geo_id={geo_id}  
        searchLocation={searchLocation}  
        searchLocationText={searchLocationText}  />    
      </div>
    </div>
    
  ) 
}

export default VulnerabilityBreakdown
