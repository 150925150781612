import React from 'react'
import Icon from '../Icon'
import searchIcon from '../../assets/images/icon/search.png'
import SearchCriteria from './SearchCriteria'
import SearchInput from './SearchInput'
import SearchPopover from './SearchPopover'

import styles from './Search.module.scss'

const Search = ({
  criteria,
  onChange,
  entity,
  activeMetros,
  showMSAs = true,
  showStates=true,
  showCounties = false,
  showHrrs = false,
  placeholder = 'Search State, MSA or Zip 3',
  width,
  height,
  searchicon = true,
  fontSize,
  searchPopOverHeight,
  overflow,
  setSearchLocationValue,
  setSearchLocationTextMethod,
  activeZip3,
}) => {

  const [hasFocus, setFocus] = React.useState(false)
  const [isPopoverVisible, setPopoverVisible] = React.useState(false)
  const [searchText, setSearchText] = React.useState('')
  const [searchValue, setSearchValue] = React.useState('')
  const criteriaRef = React.createRef()
  const inputRef = React.createRef()
  const popoverRef = React.createRef()
   
  React.useEffect(() => {
    if (hasFocus) {
      inputRef.current.focus()
      if (searchText.trim() !== '') {
        setPopoverVisible(true)
      }
    }
  }, [hasFocus, inputRef, searchText])

  return (
    <div className={styles.base} style={{ width: width, height: height }}>
      <span
        className={`${styles.input} ${hasFocus ? styles.focus : ''}`}
        onBlur={({ currentTarget, relatedTarget }) => {
          if (!currentTarget.contains(relatedTarget)) {
            setPopoverVisible(false)
          }
        }}
      >
        {criteria.length > 0 && (
          <SearchCriteria
            criteria={criteria}
            onRemove={(key) => {
              onChange(criteria.filter((prev) => prev.key !== key))
              inputRef.current.focus()
            }}
            ref={criteriaRef}
          />
        )}
        <SearchInput
          id="search"
          hasCriteria={criteria.length > 0}
          onChange={(value) => {
            setSearchText(value)
            setSearchValue(value)
            setPopoverVisible(value.trim() !== '')
          }}
          onBlur={() => setFocus(false)}
          onFocus={() => setFocus(true)}
          onKeyDown={(event) => {
            const { key } = event
            if (key === 'Backspace') {
              if (criteriaRef.current === null) return
              if (searchText.trim() !== '') return 
              criteriaRef.current
                .querySelector('[data-criteria]:last-child')
                .focus()
            }
            if (key === 'ArrowDown' || key === 'ArrowUp') {
              if (popoverRef.current === null) return
              if (searchText.trim() === '') return
              event.preventDefault()
              popoverRef.current
                .querySelector('[data-option]:first-child')
                .focus()
            }
          }}
          ref={inputRef}
          searchText={searchText}
          searchValue={searchValue}
          placeholder={placeholder}
          fontSize={fontSize}
        />
        <SearchPopover
          criteria={criteria}
          onAdd={({ key, label }) => {
            onChange([...criteria, { key, label, value: searchText.trim() }])
            setSearchText('')
            setPopoverVisible(false)
            setFocus(true)
          }}
          ref={popoverRef}
          searchText={searchText}
          visible={isPopoverVisible}
          entity={entity}
          activeMetros={activeMetros}
          showMSAs={showMSAs}
          showStates={showStates}
          activeZip3={activeZip3}
          showCounties={showCounties}
          showHrrs={showHrrs}
          searchPopOverHeight={searchPopOverHeight}
          overflow={overflow}
          setSearchText={setSearchText}
          setSearchValue={setSearchValue}
          setPopoverVisible={setPopoverVisible}
          setSearchLocationValue={setSearchLocationValue}
           setSearchLocationTextMethod={setSearchLocationTextMethod}
        />
        {searchicon ? (
          <label htmlFor="search">
            <img src={searchIcon} style={{height:'24px',width:'24px'}}></img>
          </label>
        ) : (
          ''
        )}
      </span>
    </div>
  )
}

export default Search
