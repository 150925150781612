import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styles from './Timeout.module.scss'
import Button from '../Button'
import Modal from '../Modal/Modal'
import { TbAlertCircleFilled } from 'react-icons/tb'

const Timeout = ({ endTime, showCountDownDuration = 300000, redirectPath }) => {
  const history = useHistory()
  const [timeLeft, setTimeLeft] = useState(endTime - Date.now())
  const [showCountDown, setShowCountDown] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)

  const handleClose = () => {
    setModalOpen(false)
    history.push(redirectPath)
  }

  useEffect(() => {
    const interval = setInterval(() => {
      const remainingTime = endTime - Date.now()
      if (remainingTime <= showCountDownDuration) {
        setShowCountDown(true)
      }
      if (remainingTime <= 0) {
        clearInterval(interval)
        setModalOpen(true)
      } else {
        setTimeLeft(remainingTime)
      }
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [endTime])

  const addLeadingZero = (value) => (value < 10 ? `0${value}` : value)

  const formatTime = (ms) => {
    const totalSeconds = Math.floor(ms / 1000)
    const minutes = Math.floor(totalSeconds / 60)
    const seconds = totalSeconds % 60
    return `${addLeadingZero(minutes)}m ${addLeadingZero(seconds)}s`
  }

  return (
    <div className={styles.base}>
      {showCountDown && (
        <span className={styles.timeout_text}>
          Session ends in: <b>{formatTime(timeLeft)}</b>
        </span>
      )}
      <Modal open={modalOpen} onClose={handleClose}>
        <div className={styles.modal_content}>
          <h3 className={styles.heading}>
            <TbAlertCircleFilled fontSize={60} />
            {/* Your session has timed out. You will be logged out and redirected to
            login page. */}
            Your session time is expired now, Please login again.
 
          </h3>
          <Button onClick={handleClose}>Ok</Button>
        </div>
      </Modal>
    </div>
  )
}

export default Timeout
