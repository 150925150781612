import { React, useState, useEffect } from 'react'
import styles from './RaceEthnicity.module.scss'
import SingleColumnChart from '../../components/SingleColumnChart'
import { useGeoFetch } from '../../components/GeoProvider'
import ErrorMessage from '../../components/Layout/ErrorMessage'
const RaceEthnicity = ({ geo_type, geo_id }) => {  
  const [genderAge, setGenderAge] = useState()
  const [errorCode, setErrorCode] = useState()
  const [errorMessage, setErrorMessage] = useState()
  const [errorSubject, setErrorSubject] = useState()
  const [errorOccured, seterrorOccured] = useState(false)
  const { data: raceEthnicityData, error: raceEthnicityDataError } = useGeoFetch(
    '',
    '',
    'raceethnicitydetails',
    geo_type + '/' + geo_id
  )

  useEffect(() => {
    if (raceEthnicityData !== null && raceEthnicityData !== undefined) {
      if (raceEthnicityData.response_code === 200) {       
        setGenderAge(raceEthnicityData.response_data)
      } else {
        setErrorCode(raceEthnicityData.response_code)
        setErrorMessage(raceEthnicityData.response_message)
        setErrorSubject(raceEthnicityData.response_subject)
        seterrorOccured(true)
      }
    }
  }, [raceEthnicityData])

  if (raceEthnicityDataError  || errorOccured) {
    return (
      <ErrorMessage
        errorCode={errorCode}
        errorMessage={''}
        errorSubject={errorSubject}
        errorDetails={errorMessage}
      />
    )
  }

  return genderAge ? (
    <div className={styles.base}>
      <div className={styles.maindivFormat}>
        <div>
          <span className={styles.captionFormat}>
            Race & Ethnicity
            <p className={styles.subCaptionFormat}>
            <a href='https://www.census.gov/programs-surveys/acs/guidance/estimates.html' target='_blank'>U.S. Census Bureau</a> (Jan 1,2023 - Dec 31,2023 | 0.1% margin of error)
            </p>
          </span>
        </div>

        <div>
          {genderAge !== undefined ? (
            <>
              <SingleColumnChart data={genderAge} chartName="columnBarChart" />
            </>
          ) : null}
        </div>
      </div>
    </div>
  ) : null
}

export default RaceEthnicity
