import { React, useState, useEffect } from 'react'
import styles from './GenderAgePanel.module.scss'
import ColumnChart from '../../components/ColumnChart'
import GenderAge from '../../components/ColumnChart/GenderAgeDetails'
import { useGeoFetch } from '../../components/GeoProvider'
import ErrorMessage from '../../components/Layout/ErrorMessage'
const GenderAgePanel = ({ geo_parent,geo_type, geo_id, data_type }) => {    

  const [genderAge, setGenderAge] = useState()
  const [getFromDate, setFromDate] = useState()
  const [getToDate, setToDate] = useState()
  const [errorCode, setErrorCode] = useState()
  const [errorMessage, setErrorMessage] = useState()
  const [errorSubject, setErrorSubject] = useState()
  const [errorOccured, seterrorOccured] = useState(false)
  if(geo_type==='ZIP3')
  {
    var geotype= (geo_type==='ZIP3' && geo_id ===undefined) ? "STATE": 'ZIP3'
    geo_id = (geo_type==='ZIP3' && geo_id ===undefined) ? geo_parent:geo_id
    geo_type = geotype
  }
  var genderendpoint =
    data_type === 'RSV' ? 'genderagedetails' : data_type+'genderagedetails'
  var dateendpoint = data_type === 'RSV' ? 'getdate' : 'get'+data_type+'date'
  const { data: genderAgeData, error: genderAgeDataError } = useGeoFetch(
    '',
    '',
    genderendpoint,
    geo_type + '/' + geo_id 
  )

  useEffect(() => {
    if (genderAgeData !== null && genderAgeData !== undefined) {
      if (genderAgeData.response_code === 200) {              
        setGenderAge(genderAgeData.response_data)
      } else {
        setErrorCode(genderAgeData.response_code)
        setErrorMessage(genderAgeData.response_message)
        setErrorSubject(genderAgeData.response_subject)
        seterrorOccured(true)
      }
    }
  }, [genderAgeData])

  const { data: dateData, error: dateDataError } = useGeoFetch(
    '',
    '',
    dateendpoint,
    ''
  )

  useEffect(() => {
    if (dateData !== null && dateData !== undefined) {
      if (dateData.response_code === 200) {
        setFromDate(dateData.response_data.from_date)
        setToDate(dateData.response_data.to_date)
      } else {
        setErrorCode(dateData.response_code)
        setErrorMessage(dateData.response_message)
        setErrorSubject(dateData.response_subject)
        seterrorOccured(true)
      }
    }
  }, [dateData])

  if (genderAgeDataError || dateDataError || errorOccured) {
    return (
      <ErrorMessage
        errorCode={errorCode}
        errorMessage={''}
        errorSubject={errorSubject}
        errorDetails={errorMessage}
      />
    )
  }

  return genderAge ? (
    <div className={styles.base}>
      <div className={styles.maindivFormat}>
        <div>
          <span className={styles.captionFormat}>
            Age & Gender
            {data_type==="COVID"?<p className={styles.subCaptionFormat}>
            Age and gender of individuals with COVID-19 during the last year.
            </p>:<p className={styles.subCaptionFormat}>
            Age and gender of individuals with {data_type.toUpperCase()==='FLU'?'flu':data_type} during the current respiratory disease season.
            </p>}
          </span>
        </div>

        <div>
          {genderAge !== undefined ? (
            <>
              <ColumnChart data={genderAge} chartName="genderAgechart" renderType="dashboard" />
              <GenderAge  data={genderAge}></GenderAge>
            </>
          ) : null}
        </div>
      </div>
    </div>
  ) : null
}

export default GenderAgePanel
