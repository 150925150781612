import React from "react";
import Layout from '../../components/Layout'
import ErrorMessage from '../../components/Layout/ErrorMessage'
const AccessAbort = () => {
  

  return (
    <Layout>    
    <ErrorMessage
        errorCode={'401'}
        errorMessage={''}
        errorSubject={'UnAuthorized Access'}
        errorDetails={'Requsted email id not authorized to access.'}
      />    
</Layout>
  )

  }

export default AccessAbort;
