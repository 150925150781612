import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Menu, MenuItem, MenuButton, SubMenu } from '@szhsin/react-menu'
import '@szhsin/react-menu/dist/index.css'
import styles from './index.module.scss'
import getVectorImage from '../../utils/getVectorImage'
const BindItems = ({ subItems }) => {
  const history = useHistory()

  const handleClick = (subItems) => {
    history.push(`#${subItems.combined_filter}`)
  }
  return (
    <>
      <MenuItem
        className={styles.menuItemClassName}
        key={subItems.primary_location_sub_type}
        onClick={(event) => handleClick(subItems)}
      >
        {subItems.primary_location_sub_type}{' '}
      </MenuItem>
    </>
  )
}
const Databind = ({ primaryItems }) => {
  const history = useHistory()
  const [display] = useState('arrow')
  const [align] = useState('start')
  const [position] = useState('initial')
  const [viewScroll] = useState('initial')
  const stateDataForDisplayDate = Object.entries(primaryItems[1])
  const mappedStateData = stateDataForDisplayDate.map((state) => {
    const type = state[0]
    return { ...state[1], name: type, identifier: type }
  })

  const handleClick = () => {
    history.push(`#Others`)
  }
  return (
    <>
      {primaryItems[0] === 'Other' ? (
        <MenuItem key={primaryItems[0]} onClick={(event) => handleClick()}>
          {primaryItems[0]}{' '}
        </MenuItem>
      ) : (
        <SubMenu
          className={styles.menuItemClassName}
          label={primaryItems[0]}
          align={align}
          position={position}
          viewScroll={viewScroll}
          arrow={display === 'arrow'}
          offsetX={-5}
          offsetY={-5}
        >
          {mappedStateData.map((state) => {
            return <BindItems subItems={state} key={state.identifier} />
          })}
        </SubMenu>
      )}
    </>
  )
}

const SelectList = ({
  dropdownData,
  defaultValue,
  defaultLabel,
  type,
  selectType,
  setDrillDownSelectedValueMethod,
}) => {
  const [display] = useState('default')
  const [align] = useState('start')
  const [position] = useState('anchor')
  const [viewScroll] = useState('auto')
  const history = useHistory()
  const [showMore, setShowMore] = useState(false)
  const [selectedValue, setSelectedValue] = useState(defaultValue)
  const [selectedLabel, setSelectedLabel] = useState(defaultLabel)

  const wrapperRef = useRef()

  const handleClick = () => {
    setShowMore((prev) => !prev)
  }
  const handleClick2 = (value, label) => {
    if (selectType === 'drilldown') setDrillDownSelectedValueMethod(value)
    setSelectedValue(value)
    setSelectedLabel(label)
    switch (value) {
      case 'home':
        history.push('/Dashboard')
        break
      case 'rsv':
        history.push('/RSVDashboard')
        break
      case 'flu':
        history.push('/FluDashboard')
        break
      case 'covid':
        history.push('/CovidDashboard')
        break
      case 'vulnerability':
        history.push('/VulnerabilityDashboard')
        break
      default:
        break
    }
  }

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setShowMore(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])
  return (
    <>
      <div ref={wrapperRef} onClick={handleClick}>
        <Menu
          menuButton={
            <MenuButton
              className={
                selectType !== undefined && selectType === 'drilldown'
                  ? styles.ddl1
                  : styles.ddl
              }
            >
              {selectedLabel}
              <div className={styles.dropDownIcon}>
                {' '}
                {showMore
                  ? getVectorImage('expandmore', 'auto', 'auto')
                  : getVectorImage('expandless', 'auto', 'auto')}{' '}
                {}
              </div>
            </MenuButton>
          }
          align={align}
          position={position}
          viewScroll={viewScroll}
          arrow={display === 'arrow'}
          offsetX={0}
          offsetY={0}
        >
          {dropdownData.map((option) => {
            return (
              <MenuItem
                key={option.value}
                onClick={(event) => handleClick2(option.value, option.label)}
                className={
                  selectType !== undefined && selectType === 'drilldown'
                    ? styles.item1
                    : styles.item
                }
              >
                <span className={styles.optionLabel}>{option.label}</span>
                <span className={styles.rightIcon}>
                  {option.value === selectedValue
                    ? selectType !== undefined && selectType === 'drilldown'
                      ? getVectorImage('righticon', '16px', '16px')
                      : getVectorImage('righticon', '24px', '24px')
                    : ''}
                </span>
              </MenuItem>
            )
          })}
        </Menu>
      </div>
    </>
  )
}
export default SelectList
