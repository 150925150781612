import React,{useState} from 'react'
import { useHistory } from 'react-router-dom'
import Links from './Links'
import FooterContent from './Content'
import Link from './Link'
import Logo from '../Logo'
import LinkIcon from './../../assets/images/Icon-Link-External-Site.png'
import ServiceNow from '../../pages/ServiceNow/ServiceNow'
// eslint-disable-next-line no-unused-vars
import { PRIVACY_POLICY, TERMS_OF_USE,MAIL_TO } from './constants'
import { useAuth } from '../AuthProvider'
import styles from './Footer.module.scss'
import dayjs from 'dayjs'



const Footer = ({ appName, logoUrl, children = null }) => {  
  const history = useHistory()
  const [{ isAuthenticated }] = useAuth()
  const [showInfoDialog, setShowInfoDialog] = useState(false)
  const [showIncidentModal, setShowIncidentModal] = useState(false)

  const handleReportIssueClick = (e) => {
    e.preventDefault()
    setShowIncidentModal((prev) => !prev)
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      setShowIncidentModal((prev) => !prev)
    }
  }

  const handleClose = () => {
    setShowIncidentModal(false)
  }
  return (
    <>

      <footer className={styles.base}>
        <div className={styles.footerDiv}>
          <div className={styles.copyright}>
            <div>
              <a className={`${styles.logo} ${styles.pointer}`} href={logoUrl}>
                <Logo color={'#FFFFFF'} />
              </a>
            </div>
          </div>
          <div className={styles.copyrightdes}>
            &copy; {dayjs().format('YYYY')} Optum Inc. All rights reserved.
          </div>
        </div>
        <div className={styles.disclaimer}>
          <div className={styles.linksDiv}>
            <ul>
              <li>
                <a href={PRIVACY_POLICY} target="_blank" rel="noopener noreferrer">
                  Privacy Policy
                  <img src={LinkIcon} className={styles.iconImage}></img>
                </a>
              </li>
              <li>
                <a href={TERMS_OF_USE} target="_blank" rel="noopener noreferrer">
                  Terms of Use
                  <img src={LinkIcon} className={styles.iconImage}></img>
                </a>
              </li>
              <li>
                <a href={'mailto:'+MAIL_TO} target="_blank" rel="noopener noreferrer">
                  Contact Us
                  {/* <img src={LinkIcon} className={styles.iconImage}></img> */}
                </a>
              </li>

             {isAuthenticated &&  <li>               
<a
                role="button"
                onKeyDown={handleKeyDown}
                tabIndex={0}
                onClick={handleReportIssueClick}
                className={styles.link}
              >
                Report an issue
              </a>
              </li>
}
            </ul>
          </div>
          <div className={styles.DisclaimerDiv}>
            <p className={styles.contentFormat}>
              The content, forecasts, and predictions offered are for informational purposes only, and nothing in the forecasts, predictions and related information should be considered, or used as a substitute for, medical advice, diagnosis or treatment.   Users should seek the advice of a physician or other qualified health care provider with any questions regarding personal health or medical conditions. Optum and its affiliates and licensors make no representations or warranties of any kind, express or implied, with respect to these forecasts, predictions, any related data, and/or the techniques used to create such forecasts and predictions, including, but not limited to, any warranties of performance, merchantability, fitness for a particular purpose, title/non-infringement, and quality, accuracy and/or completeness of these forecasts, predictions, and related information. Any interpretation or use of these forecasts, predictions, and related information is solely and exclusively at your own discretion and risk, and Optum and its affiliates and licensors will have no liability for any damages or claims relating to such interpretation or use.
            </p>
          </div>
        </div>
      </footer>
      {showIncidentModal && (
        <ServiceNow open={showIncidentModal} handleClose={handleClose} />
      )}
    </>

  )
}

Footer.Links = Links
Footer.Content = FooterContent
Footer.Link = Link

export default Footer
