import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import LinkButton from '../../../components/LinkButton'
import getVectorImage from '../../../utils/getVectorImage'
import styles from './SideTable.module.scss'
import statesMap from '../../../utils/stateMap'
import { useGeoState } from '../../../components/GeoProvider'

const Databind = ({ state, chartRef, geo_parent }) => {
  const history = useHistory()
  const {regionSelected} = useGeoState()
  const handleMouseOver = (element) => {
    const chart = chartRef.current.chart
    var indexSeries = regionSelected === "state" ? 1 : regionSelected === "zip3" ? 2 : 0
    if (chart !== undefined && chart !== null) {
      const point = chart.series[indexSeries].points.find(
        (point) => point.geo_id === element.getAttribute('geo_id')
      )
      point && chart.tooltip.refresh(point)
    }
 
  }
  const handleMouseOut = () => {
    const chart = chartRef.current.chart
    if (chart && chart.tooltip) {
      chart.tooltip.destroy()
    }
  }
  let istrue=state.change_percentage>0?true:false
  return (
    <div style={{ display: 'flex' }} className={styles.Layout}>
      <div
        style={{
          width: '75%',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
        }}
        title={state.identifier}
        geo_id={state.geo_id}
      >
        <LinkButton
          onMouseOver={(e) => handleMouseOver(e.target)}
          onMouseOut={() => handleMouseOut()}
          className={styles.btn}
          onClick={() => {
            history.push(`/VulnerabilityMSADashboard/${geo_parent}/MSA/${state.geo_id}`)
          }}
          data-title={state.identifier} 
          title={state.identifier}
          geo_id={state.geo_id}
        >
          {state.identifier.length > 12
            ? state.identifier.substring(0, 12) + '...'
            : state.identifier}
        </LinkButton>
      </div>
      <div className={styles.columnRate}>{state.vulnerability_index}</div>
      <div className={styles.columnDays}>
        <span> {getVectorImage(istrue)} </span>
        <span
          className={
            istrue ? styles.percentageRed : styles.percentageGreen
          }
        >
          {' '}
          {state.change_percentage}%
        </span>
      </div>
      
    </div>
  )
}

const SideTable = ({ stateData, chartRef, geo_parent, geo_id }) => {
  const {  isChecked,setIsChecked } = useGeoState()
  const geo_name = statesMap()[geo_id]
  const stateDataForDisplayDate = Object.entries(stateData)

  const mappedStateData = stateDataForDisplayDate.map((state) => {
    const stateName = state[1].geo_name
    return { ...state[1], name: stateName, identifier: stateName }
  })

  let filteredStateData = mappedStateData

  let noDataFlag = filteredStateData.length === 0 ? true : false
  const [getFilter, setfilter] = useState('down')
  const [getStateFilter, setStatefilter] = useState('down')
  const sortBy = (action) => {
    if (action === 'up') {
      setfilter('down')
      stateData.sort((a, b) => b.vulnerability_index - a.vulnerability_index)
    } else if (action === 'down') {
      setfilter('up')
      stateData.sort((a, b) => a.vulnerability_index - b.vulnerability_index)
    }
  }
  const sortStateBy = (action) => {
    if (action === 'up') {
      setStatefilter('down')
      stateData.sort((a, b) => a.geo_name > b.geo_name ? 1 : -1)
    } else if (action === 'down') {
      setStatefilter('up')
      stateData.sort((a, b) => a.geo_name > b.geo_name ? -1 : 1)
    }
  }
  return (
    <div className={styles.base}>
      <div className={styles.header}>
        <div
          style={{
            width: '100%',
            justifyContent: 'flex-start',
            marginTop: '16px',
          }}
        >
          <div style={{ display: 'flex' }}>
            <div
              style={{
                display: 'inline-flex',
                width: '99%',
              }}
            >
              <span>
                <h5
                  style={{
                    width: '209px',
                    height: '24px',
                    fontStyle: 'normal',
                    fontWeight: '700',
                    fontSize: '20.25px',
                    lineHeight: '24px',
                    color: '#002677',
                  }}
                >
                  Metro Area Incidence
                </h5>
              </span>
            
            </div>
           
          </div>
          <div
            style={{
              fontSize: '12px',
              display: 'flex',
              lineHeight: '18px',
              padding: '1rem 0rem 0',
            }}
          >
            <span>
              {' '}
              <svg
                height="20"
                width="20"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                focusable="false"
                aria-hidden="true"
              >
                <path d="M11 7h2v2h-2zm1 10c.55 0 1-.45 1-1v-4c0-.55-.45-1-1-1s-1 .45-1 1v4c0 .55.45 1 1 1zm0-15C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"></path>
              </svg>
            </span>{' '}
            &nbsp;&nbsp;
            {noDataFlag ? (
              <div
                style={{ '&::WebkitScrollbar': { width: 0, height: 0 } }}
                className={styles.sideTableHeader}
              >
                {' '}
                {geo_name} MSA info is not available right now.
              </div>
            ) : (
              <div
                style={{ '&::WebkitScrollbar': { width: 0, height: 0 } }}
                className={styles.sideTableHeader}
              >
                {' '}
                Rate represents the incidence of respiratory virus activity in a given month. Change indicates the difference in incidence since the previous month.
              </div>
            )}
          </div>
        </div>
        {!noDataFlag && (
          <div
            style={{
              padding: '0rem 0rem',
              paddingBottom: '0.5rem',
              paddingTop: '0.5rem',
              borderTop: '1px solid #CBCCCD',
              borderBottom: '2px solid #002677',
              marginTop: '5px',
            }}
          >
            <div style={{ display: 'inline-flex', width: '54%' }}>
            <h4  onClick={() => sortStateBy(getStateFilter)}>Metro Area {'  '}
            {/* <span style={{ marginLeft: '4px',cursor: 'pointer' }}>
                {getVectorImage(getStateFilter)}
              </span> */}
              </h4>
            </div>
            <div
            style={{
              display: 'inline-flex',
              width: '20%',
              justifyContent: 'flex-middle',
             
            }}
          >
            <h4 onClick={() => sortBy(getFilter)} >
              Rate{' '}
              <span style={{ marginLeft: '4px',cursor: 'pointer' }}>
                {getVectorImage(getFilter)}
              </span>
            </h4>
          </div>
          <div
            style={{
              display: 'inline-flex',
              width: '26%',
              justifyContent: 'flex-middle',
            }}
          >
            <h4>Change</h4>
          </div>
           
          </div>
        )}
      </div>

      <div className={styles.scroll}>
        {filteredStateData.map((state) => {
          return (
            <Databind
              state={state}
              key={state.geo_id}
              chartRef={chartRef}
              geo_parent={geo_parent}
            />
          )
        })}
      
      </div>
    </div>
  )
}

export default SideTable
