const typeDisplayOptions = [
  [
  { value: 'home', label: 'My Dashboard' },
  { value: 'rsv', label: 'RSV Incidence' },
  { value: 'flu', label: 'Flu Incidence' },
  { value: 'covid', label: 'COVID-19 Incidence' },
  { value: 'vulnerability', label: 'Vulnerability' },
  // { value: 'flunew', label: 'New Flu Incidence' },
  // { value: 'birth', label: 'Births & Trends' },
  // { value: 'allergies', label: 'Allergies' },
  // { value: 'respiratory', label: 'Respiratory' },
],
[{ value: 'home', label: 'My Dashboard' },
{ value: 'rsv', label: 'RSV Incidence' },
{ value: 'flu', label: 'Flu Incidence' },
{ value: 'covid', label: 'COVID-19 Incidence' },
]
]

export default typeDisplayOptions
