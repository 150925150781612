import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import LinkButton from '../../components/LinkButton'
import getVectorImage from '../../utils/getVectorImage'
import styles from './SideTable.module.scss'
import toTitleCase from '../../utils/toTitleCase'
import { useGeoState } from '../../components/GeoProvider'
const Databind = ({ state, chartRef }) => {
  const {regionSelected} = useGeoState()
  const history = useHistory()
  const handleMouseOver = (element) => {
    const chart = chartRef.current.chart
    var indexSeries = regionSelected === "metro" ? 1 : regionSelected === "zip3" ? 2 : 0
    if (chart !== undefined && chart !== null) {
      const point = chart.series[indexSeries].points.find(
        (point) => point.geo_id === element.attributes['geo_id'].textContent
      )
      point && chart.tooltip.refresh(point)
    }
 
  }
  let istrue=state.change_percentage>0?true:false

  const handleMouseOut = () => {
    const chart = chartRef.current.chart
    if (chart && chart.tooltip) {
      chart.tooltip.destroy()
    }
  }
  const handleClick = () => {
    regionSelected.toUpperCase() === 'METRO' ?
    history.push(`/VulnerabilityMSADashboard/${state.geo_parent}/MSA/${state.geo_id}`) :
      history.push(`/VulnerabilityStateDashboard/STATE/${state.geo_id}`)
  }
  let statename=(regionSelected.toUpperCase()==='STATE'?state.identifier+' ('+state.geo_id+')':state.identifier)
  return (
    <div style={{ display: 'flex' }} className={styles.Layout}>
      <div
        style={{
          width: '75%',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
        }}
        title={state.identifier}
      >
        <LinkButton
          onMouseOver={(e) => handleMouseOver(e.target)}
          onMouseOut={() => handleMouseOut()}
          className={styles.btn}
          onClick={handleClick}
          data-title={statename}
          title={statename}
          geo_id={state.geo_id}
        >
          {statename}
        </LinkButton>
      </div>
      <div className={styles.columnRate}>{state.vulnerability_index}</div>
      <div className={styles.columnDays}>
        <span> {getVectorImage(istrue)} </span>
        <span
          className={
            istrue ? styles.percentageRed : styles.percentageGreen
          }
        >
          {' '}
          {state.change_percentage}%
        </span>
      </div>
    </div>
  )
}

const SideTable = ({ stateData, chartRef }) => {

  const {  isChecked,setIsChecked,regionSelected } = useGeoState()
  const stateDataForDisplayDate = Object.entries(stateData)
  
  const mappedStateData = stateDataForDisplayDate.map((state) => {
    const stateName = state[1].geo_name
    return { ...state[1], name: stateName, identifier: stateName }
  })

  let filteredStateData = mappedStateData
  const [getFilter, setfilter] = useState('down')
  const [getStateFilter, setStatefilter] = useState('down')
  let noDataFlag = filteredStateData.length === 0 ? true : false
  const sortBy = (action) => {
    if (action === 'up') {
      setfilter('down')
      stateData.sort((a, b) => b.vulnerability_index - a.vulnerability_index)
    } else if (action === 'down') {
      setfilter('up')
      stateData.sort((a, b) => a.vulnerability_index - b.vulnerability_index)
    }
  }
  const sortStateBy = (action) => {
    if (action === 'up') {
      setStatefilter('down')
      stateData.sort((a, b) =>a.geo_name > b.geo_name ? 1 : -1)
    } else if (action === 'down') {
      setStatefilter('up')
      stateData.sort((a, b) =>a.geo_name > b.geo_name ? -1 : 1)
    }
  }
  return (
    <div className={styles.base}>
      <div className={styles.header}>
        <div
          style={{
            width: '100%',
            justifyContent: 'flex-start',
            marginTop: '16px',
          }}
        >
          <div style={{ display: 'flex' }}>
            <div
              style={{
                display: 'inline-flex',
                width: '99%',
              }}
            >
              <span>
                <h5
                  style={{
                    fontFamily: 'Optum Sans',
                    fontSize: '21px',
                    fontWeight: '600',
                    color: '#002677',
                  }}
                >
                   {regionSelected==='metro'?toTitleCase(regionSelected)+' Area Incidence':toTitleCase(regionSelected)+' Incidence'}
                </h5>
              </span>
            </div>
            {/* <div className={styles.subCaptionFormat} onClick={() => setIsChecked(!isChecked)}>
              <div style={{ '&::WebkitScrollbar': { width: 0, height: 0 } }}>
           
              <a target='_blank'>Factors</a>{'   '}
              </div>
               <span className={styles.iconImage}>
               {getVectorImage('medical_factors','20px','20px')}
              
            </span>{' '}
            &nbsp;&nbsp;
            
            </div> */}
          </div>
          <div
            style={{
              fontSize: '12px',
              display: 'flex',
              lineHeight: '18px',
              padding: '1rem 0rem 0',
            }}
          >
            <span> {getVectorImage('Info', '16px', '16px')}</span> &nbsp;&nbsp;
            <div
              style={{ '&::WebkitScrollbar': { width: 0, height: 0 } }}
              className={styles.sideTableHeader}
            >
              {' '}
              Rate represents the incidence of respiratory virus activity in a given month. Change indicates the difference in incidence since the previous month.
            </div>
          </div>
        </div>
        <div
          style={{
            padding: '0rem 0rem',
            paddingBottom: '0.5rem',
            paddingTop: '0.5rem',
            borderTop: '1px solid #CBCCCD',
            borderBottom: '2px solid #002677',
            marginTop: '5px',
          }}
        >
          <div style={{ display: 'inline-flex', width: '54%'  }}>
            <h4  onClick={() => sortStateBy(getStateFilter)}>{regionSelected==='metro'?toTitleCase(regionSelected)+' Area':toTitleCase(regionSelected)} {'  '}
            {/* <span style={{ marginLeft: '4px',cursor: 'pointer' }}>
                {getVectorImage(getStateFilter)}
              </span> */}
              </h4>
          </div>
          <div
            style={{
              display: 'inline-flex',
              width: '20%',
              justifyContent: 'flex-middle',
             
            }}
          >
            <h4 onClick={() => sortBy(getFilter)} >
              Rate{' '}
              <span style={{ marginLeft: '4px',cursor: 'pointer' }}>
                {getVectorImage(getFilter)}
              </span>
            </h4>
          </div>
          <div
            style={{
              display: 'inline-flex',
              width: '26%',
              justifyContent: 'flex-middle',
            }}
          >
            <h4>Change</h4>
          </div>
        </div>
      </div>

      <div className={styles.scroll}>
        {filteredStateData.map((state) => {
          
          return (
            <Databind state={state} key={state.statename} chartRef={chartRef} />
          )
        })}
      </div>
    </div>
  )
}

export default SideTable
