import React, { useState, useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { useGeoState, useGeoFetch } from '../../components/GeoProvider'
import ErrorMessage from '../../components/Layout/ErrorMessage'
import EmptyMap from '../../components/Map/emptyMap'
import Map from '../../components/Map'
import toolTipFormatter from './toolTipFormatter'
import MapLegend from './MapLegendHorizontalVulnerability'
import styles from './RSVMap.module.scss'
import { displayDate } from './dates'
const VulnerabilityMap = ({ zoomLocation }) => {
  
  const chartRef = useRef(null)
  const location = useLocation()
  const hash = location.hash
  const [getMapStates, setMapStates] = useState()
  const [errorCode, setErrorCode] = useState()
  const [errorMessage, setErrorMessage] = useState()
  const [errorSubject, setErrorSubject] = useState()
  const [errorOccured, seterrorOccured] = useState(false)
  const [getStateJson, setStateJson] = useState()
  const { mapOptions, regionSelected, setRegionSelected,setDisplayDate } = useGeoState()
  if (hash) {
    if (hash.split('-').length > 1) {
      setRegionSelected(hash.split('-')[0].replace('#', ''))
    }
  }
  const { data: statesData, error: MapError } = useGeoFetch(
    displayDate,
    displayDate,
    'vulnerability',
    'getdashmap/STATE?geo_parent=US'
  )

  useEffect(() => {
    if (statesData !== null && statesData !== undefined) {
      if (statesData.response_code === 200) {        
        setMapStates(statesData.response_data)
        var datacount= Object.keys(statesData.response_data).length-1        
        setDisplayDate(Object.keys(statesData.response_data)[datacount])
      } else {
        setErrorCode(statesData.response_code)
        setErrorMessage(statesData.response_message)
        setErrorSubject(statesData.response_subject)
        seterrorOccured(true)
      }
    }
  }, [statesData])

  useEffect(() => {
    let url = `${window.location.origin}/usStatesAll.json`
    fetch(url)
      .then(function (res) {
        return res.json()
      })
      .then(function (data) {
        setStateJson(data)
      })
      .catch(function (err) {
        console.log(err, ' error file usStatesAll.json')
      })
  }, [1])

  if (MapError || errorOccured) {
    return (
      <ErrorMessage
        errorCode={errorCode}
        errorMessage={errorMessage}
        errorSubject={errorSubject}
        errorDetails={errorMessage}
      />
    )
  }

  return (
    <div className={`${styles.base}`}>
      {regionSelected === 'state' && getStateJson!==undefined&& (
        <>
          {getMapStates !== undefined && getStateJson ? (
            <>
              <Map
               
                toolTipFormatter={toolTipFormatter}
                  activeCbsasData={null}
                  statesData={getMapStates}
                  cbsaData={null}
                  mapHeight="35vh"
                  mapWidth="50vh"
                  mapOptions={mapOptions}
                  dynamicMapTitle={true}
                  mapType="RSVMAP"
                  enableButtons={false}
                  enableTooltip={false}
                  chartRef={chartRef}
                  mapDataType={0}
                  stateJson={getStateJson}
                  dashboardType="Vulnerability"
                  enableMouseTracking={false}
              
                  
              />
              <MapLegend className={styles.mapLegend} />
            </>
          ) : (
            <EmptyMap
              mapHeight="30vh"
              mapWidth="60vh"
              stateJson={getStateJson}
            />
          )}
        </>
      )}
    </div>
  )
}

export default VulnerabilityMap
